<template>
  <div>
    <el-card style="margin-bottom: 20px">
      <el-form
        class="query-form"
        :inline="true"
        :model="formQuery"
        ref="formRef"
      >
        <el-form-item label="订单id" prop="order_id"
          ><el-input v-model="formQuery.order_id"
        /></el-form-item>
        <el-form-item label="订单号" prop="order_num"
          ><el-input v-model="formQuery.order_num"
        /></el-form-item>
        <el-form-item label="用户名" prop="username"
          ><el-input v-model="formQuery.username"
        /></el-form-item>
        <el-form-item label="订单状态" prop="status">
          <el-select v-model="formQuery.status" placeholder="Select">
            <el-option :value="1" label="待确认" />
            <el-option :value="2" label="待发货" />
            <el-option :value="3" label="已发货" />
            <el-option :value="4" label="已返售" />
          </el-select>
        </el-form-item>
        <el-form-item label="订单生成时间" prop="created_at_start">
          <el-date-picker
            v-model="formQuery.created_at_start"
            type="date"
            style="width: 100%"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item label="订单结束时间" prop="created_at_end">
          <el-date-picker
            v-model="formQuery.created_at_end"
            type="date"
            style="width: 100%"
            value-format="YYYY-MM-DD"
          />
        </el-form-item>
        <el-form-item>
          <el-button :icon="Brush" @click="resetForm" />
          <el-button type="primary" :icon="Search" @click="getData"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table :height="tableHeight" :data="orderList" style="width: 100%">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" sortable />
        <el-table-column prop="order_num" label="订单号" width="250" sortable />
        <el-table-column
          prop="username"
          label="客户名称"
          width="150"
          sortable
        />
        <el-table-column prop="status" label="充值状态">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 1">待确认</el-tag>
            <el-tag v-if="scope.row.status === 2" type="info">待发货</el-tag>
            <el-tag v-if="scope.row.status === 3" type="success">已发货</el-tag>
            <el-tag v-if="scope.row.status === 4" type="warning">已返售</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="创建时间"
          min-width="200"
          sortable
        >
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-icon>
                <timer />
              </el-icon>
              <span style="margin-left: 10px">{{ scope.row.created_at }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="140">
          <template #default="scope">
            <el-button type="primary" @click="openDetailDialog(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
        <template #empty>
          <div style="margin: 50px 0">
            <el-empty
              v-if="orderList.length === 0"
              description="暂时还没有数据哦"
            />
          </div>
        </template>
      </el-table>
      <div style="padding-top: 15px">
        <el-pagination
          layout="total, prev, pager, next"
          :current-page="pageData.pageNum"
          :page-size="pageData.pageSize"
          :total="pageData.total"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChangePrev"
          @next-click="handleCurrentChangeNext"
          background
        />
      </div>
    </el-card>
    <el-dialog
      title="盲盒订单详情"
      v-model="DetailDialogVisible"
      center
      width="80%"
    >
      <el-descriptions title="盲盒分类" :column="2" border>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">盲盒分类</div>
          </template>
          {{ brandData.brand_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">盲盒价格</div>
          </template>
          {{ orderDetail.brand_price }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" title="订单详情" :column="2" border>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">订单号</div>
          </template>
          {{ orderDetail.order_num }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">订单价格</div>
          </template>
          {{ orderDetail.price }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">订单状态</div>
          </template>
          <el-tag v-if="orderDetail.status === 1">待确认</el-tag>
          <el-tag v-if="orderDetail.status === 2" type="info">待发货</el-tag>
          <el-tag v-if="orderDetail.status === 3" type="success">已发货</el-tag>
          <el-tag v-if="orderDetail.status === 4" type="warning">已返售</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">运单号</div>
          </template>
          {{ orderDetail.shipping_order }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">惊喜款产品订单</div>
          </template>
          {{ orderDetail.surprise_order === 1 ? "是" : "否" }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" title="客户明细" :column="2" border>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">客户名称</div>
          </template>
          {{ userData.username }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">客户组</div>
          </template>
          {{ userData.user_group }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">客户邮箱</div>
          </template>
          {{ userData.email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">客户电话</div>
          </template>
          {{ userData.phone }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" title="配送信息" :column="1" border>
        <el-descriptions-item label="收件人">{{
          addressData.first_name + " " + addressData.last_name
        }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{
          addressData.phone
        }}</el-descriptions-item>
        <el-descriptions-item label="地址">{{
          addressData.address +
          ", " +
          addressData.city +
          (addressData.postcode ? ", " + addressData.postcode : "") +
          (addressData.state_province
            ? ", " + addressData.state_province
            : "") +
          (addressData.country ? ", " + addressData.country : "")
        }}</el-descriptions-item>
      </el-descriptions>
      <div class="margin-top">
        <h3>订单商品</h3>
        <el-table :data="productData" style="width: 100%; margin-top: 1rem">
          <el-table-column prop="thumb" label="图片">
            <template #default="scope">
              <div style="display: inline-flex" v-if="scope.row.thumb">
                <el-image class="goods_image" :src="scope.row.thumb" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="box_goods_name"
            label="盲盒商品名称"
            width="250"
          />
          <el-table-column prop="number" label="数量" />
          <el-table-column prop="cost_price" label="成本价" />
          <el-table-column prop="is_surprise" label="是否惊喜款">
            <template #default="scope">
              <el-tag v-if="scope.row.is_surprise === 2" type="info">否</el-tag>
              <el-tag v-if="scope.row.is_surprise === 1" type="success"
                >是</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getBlindBoxOrderDetail, getBlindBoxOrderList } from "@/axios/api";
import {
  Brush,
  Delete,
  Edit,
  Search,
  Timer,
  WarningFilled,
} from "@element-plus/icons-vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "blindBoxesOrderList",
  components: { Timer },
  setup() {
    const router = useRouter();
    const pageData = ref({
      total: 0,
      pageNum: 1,
      pageSize: 50,
    });
    const formQuery = ref({
      order_id: "",
      order_num: "",
      status: "",
      created_at_start: "",
      created_at_end: "",
    });
    const orderList = ref([]);

    getData();
    async function getData() {
      try {
        let params = formQuery.value;
        params.created_at_start = params.created_at_start
          ? params.created_at_start + " 00:00:00"
          : "";
        params.created_at_end = params.created_at_end
          ? params.created_at_end + " 00:00:00"
          : "";
        params.page = pageData.value.pageNum;
        params.limit = pageData.value.pageSize;
        const data = await getBlindBoxOrderList({ params });
        orderList.value = data.data;
        pageData.value.total = data.data.length;
      } catch (error) {
        console.log(error);
      }
    }

    // 重置表单
    const formRef = ref();
    const resetForm = () => {
      formRef.value.resetFields();
      getData();
    };
    // 分页，处理函数
    const handleCurrentChangePrev = (val) => {
      pageData.value.pageNum = val;
    };
    const handleCurrentChange = (val) => {
      pageData.value.pageNum = val;
      getData();
    };
    const handleCurrentChangeNext = (val) => {
      pageData.value.pageNum = val;
    };

    const tableHeight = ref("0px");
    onMounted(() => {
      const extraHeight = document.querySelector(".query-form").offsetHeight;
      tableHeight.value = `calc(100vh - ` + (extraHeight + 270) + `px)`;
    });

    const DetailDialogVisible = ref(false);
    const orderDetail = ref({});
    const brandData = ref({});
    const userData = ref({});
    const addressData = ref({});
    const productData = ref([]);
    const openDetailDialog = async (item) => {
      try {
        let params = { order_id: item.id };
        const data = await getBlindBoxOrderDetail({ params });
        orderDetail.value = data.data.orders_data;
        brandData.value = data.data.brand_data;
        userData.value = data.data.user_data;
        addressData.value = data.data.order_addr;
        productData.value.push(data.data.product_data);
        DetailDialogVisible.value = true;
      } catch (error) {
        console.log(error);
      }
    };
    return {
      Brush,
      Search,
      Edit,
      Delete,
      WarningFilled,

      formRef,
      pageData,
      formQuery,
      tableHeight,
      orderList,
      DetailDialogVisible,
      orderDetail,
      brandData,
      userData,
      addressData,
      productData,

      getData,
      resetForm,
      handleCurrentChangePrev,
      handleCurrentChange,
      handleCurrentChangeNext,
      openDetailDialog,
    };
  },
};
</script>
<style lang="less" scoped>
.margin-top {
  margin-top: 2rem;
}
.goods_image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
</style>
