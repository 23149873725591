<template>
  <div>
    <!-- 客户查询 -->
    <el-card>
      <el-form
        :inline="true"
        :model="query"
        label-width="80px"
        ref="query"
        class="query_form"
      >
        <el-col :span="24">
          <el-form-item label="客户姓名" prop="username">
            <el-input v-model="query.username" placeholder="客户名称" />
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="query.email" placeholder="邮箱" />
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-select v-model="query.status" class="m-2" placeholder="请选择">
              <el-option
                v-for="item in CustomersOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-form-item label="注册日期">
          <el-col :span="10">
            <el-form-item prop="created_at_start" style="margin-right: unset">
              <el-date-picker
                v-model="query.created_at_start"
                type="date"
                placeholder="开始日期"
                style="width: 100%"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </el-col>
          <el-col class="text-center" :span="2">
            <span class="text-gray-500">-</span>
          </el-col>
          <el-col :span="10">
            <el-form-item prop="created_at_end">
              <el-date-picker
                v-model="query.created_at_end"
                type="date"
                placeholder="结束日期"
                style="width: 100%"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" :icon="Search" @click="getCustomerList"
            >查询</el-button
          >
          <el-button type="primary" :icon="Brush" @click="resetForm" />
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 客户列表 -->
    <el-card>
      <el-table :data="customerList" :height="tableHeight" style="width: 100%">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" width="55" />
        <el-table-column prop="email" label="邮箱" min-width="180" />
        <el-table-column prop="username" label="客户名称" min-width="150" />
        <el-table-column prop="status" label="状态" width="100">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 2" type="danger">禁用</el-tag>
            <el-tag v-if="scope.row.status === 1" type="success">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" min-width="150" />
        <el-table-column prop="wechat" label="微信号" min-width="150" />
        <el-table-column prop="created_at" label="注册日期" min-width="180" />
        <el-table-column fixed="right" label="操作" width="100">
          <template #default="scope">
            <el-button
              type="primary"
              @click="editCustomerDetails(scope.$index, scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <template #empty>
          <div style="margin: 50px 0">
            <el-empty v-if="showEmpty" description="暂时还没有客户哦" />
          </div>
        </template>
      </el-table>
      <div style="padding-top: 15px">
        <el-pagination
          layout="total, prev, pager, next"
          :current-page="pageNum"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChangePrev"
          @next-click="handleCurrentChangeNext"
          background
        />
      </div>
    </el-card>
    <!-- 客户编辑弹窗 -->
    <el-dialog
      custom-class="customer-edit-dialog"
      v-model="customerDialogVisible"
      width="80%"
      title="编辑客户"
    >
      <div class="dialog-content">
        <div style="margin-bottom: 20px">
          <el-button
            type="primary"
            :icon="CirclePlusFilled"
            @click="addAddressTab(editableTabsValue)"
          >
            添加地址
          </el-button>
        </div>
        <el-tabs
          v-model="editableTabsValue"
          tab-position="left"
          type="card"
          class="demo-tabs"
          @tab-remove="removeTab"
        >
          <el-tab-pane
            v-for="item in editableTabs"
            :key="item.name"
            :label="item.title"
            :name="item.name"
            :closable="item.name !== 'customer_info'"
          >
            <div
              class="customer-edit-from"
              v-if="item.name === 'customer_info'"
            >
              <el-form :model="customersInfoForm" label-width="120px">
                <el-form-item label="姓名">
                  <el-input v-model="customersInfoForm.username" />
                </el-form-item>
                <el-form-item label="邮箱">
                  <el-input v-model="customersInfoForm.email" />
                </el-form-item>
                <el-form-item label="电话">
                  <el-input v-model="customersInfoForm.phone" />
                </el-form-item>
                <el-form-item label="状态">
                  <el-select
                    v-model="customersInfoForm.status"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in CustomersOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="订阅邮箱">
                  <el-input v-model="customersInfoForm.subscribe_email" />
                </el-form-item>
              </el-form>
            </div>
            <div
              class="customer-edit-from"
              v-if="item.name !== 'customer_info'"
            >
              <el-form :model="customerAddress[item.name]" label-width="120px">
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item label="姓名">
                      <el-input
                        v-model="customerAddress[item.name].first_name"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="姓氏">
                      <el-input
                        v-model="customerAddress[item.name].last_name"
                      /> </el-form-item
                  ></el-col>
                </el-row>
                <el-form-item label="国家/地区">
                  <el-select
                    v-model="customerAddress[item.name].country"
                    class="m-2"
                    placeholder="Select"
                    @change="changeCountry([item.name])"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.country"
                      :value="item.country"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="州/省">
                  <el-select
                    v-model="customerAddress[item.name].state_province"
                    class="m-2"
                    placeholder="Select"
                  >
                    <el-option
                      v-for="(item, index) in stateOptions[item.name]"
                      :key="index"
                      :label="item"
                      :value="item"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="城市">
                  <el-input v-model="customerAddress[item.name].city" />
                </el-form-item>
                <el-form-item label="详细地址">
                  <el-input v-model="customerAddress[item.name].address" />
                </el-form-item>
                <el-row :gutter="24">
                  <el-col :span="12">
                    <el-form-item label="邮编">
                      <el-input v-model="customerAddress[item.name].postcode" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="电话">
                      <el-input
                        v-model="customerAddress[item.name].phone"
                      /> </el-form-item
                  ></el-col>
                </el-row>
                <el-form-item label="设为默认地址">
                  <el-switch
                    v-model="customerAddress[item.name].is_default"
                    :active-value="1"
                    :inactive-value="2"
                  />
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="customerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="editCustomer">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { Brush, CirclePlusFilled, Search } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import options from "../../assets/js/addressJson";
import { getUserInfo, getUserlist, storeUser } from "../../axios/api";
export default {
  name: "CustomerList",
  components: {},
  setup() {
    const tableHeight = ref("0px");
    const CustomersOptions = [
      {
        value: 2,
        label: "禁用",
      },
      {
        value: 1,
        label: "启用",
      },
    ];
    const customerAddress = ref([]);
    const stateOptions = ref([]);
    function changeCountry(address) {
      customerAddress.value[address].state_province = "";
      options.forEach((item) => {
        if (item.country === customerAddress.value[address].country) {
          stateOptions.value[address] = item.zones;
        }
      });
    }

    return {
      Brush,
      Search,
      CirclePlusFilled,
      CustomersOptions,
      tableHeight,
      options,
      stateOptions,
      changeCountry,
      customerAddress,
    };
  },
  data() {
    return {
      query: {
        username: "",
        email: "",
        created_at_start: "",
        created_at_end: "",
        status: "",
      },

      customerList: [],

      // 分页
      total: 0,
      pageNum: 1,
      pageSize: 50,

      showEmpty: false,

      customerDialogVisible: false,
      tabIndex: 0,
      editableTabsValue: "customer_info",
      editableTabs: [
        {
          title: "基本信息",
          name: "customer_info",
        },
      ],

      customersInfoForm: {},
    };
  },
  mounted() {
    this.getCustomerList();
    const extraHeight = document.querySelector(".query_form").offsetHeight;
    const mianHeight = document.querySelector(".main").offsetHeight;
    this.tableHeight = `${mianHeight - extraHeight - 181}px`;
  },
  methods: {
    // 重置表单
    resetForm() {
      this.$refs["query"].resetFields();
      this.getCustomerList();
    },
    //获取客户列表
    async getCustomerList() {
      try {
        this.query.page = this.pageNum;
        this.query.limit = this.pageSize;
        this.query.created_at_start = this.query.created_at_start
          ? this.query.created_at_start + " 00:00:00"
          : "";
        this.query.created_at_end = this.query.created_at_end
          ? this.query.created_at_end + " 00:00:00"
          : "";
        let params = this.query;
        const data = await getUserlist({ params });
        this.customerList = data.data;
        this.total = data.count;
        if (this.customerList.length === 0) {
          this.showEmpty = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 分页，处理函数
    handleCurrentChangePrev(val) {
      this.pageNum = val;
      console.log(`上一页: ${val}`);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getCustomerList();
      console.log(`当前页: ${val}`);
    },
    handleCurrentChangeNext(val) {
      this.pageNum = val;
      console.log(`下一页: ${val}`);
    },
    //编辑用户数据
    async editCustomerDetails(index, row) {
      let params = { user_id: row.id };
      const data = await getUserInfo({ params });
      this.customersInfoForm = data.data.user_detail;
      this.customerAddress = data.data.user_address;
      this.editableTabs = [
        {
          title: "基本信息",
          name: "customer_info",
        },
      ];
      this.tabIndex = 0;
      this.editableTabsValue = "customer_info";
      if (this.customerAddress.length > 0) {
        this.tabIndex = this.customerAddress.length;
        this.customerAddress.forEach((item, index) => {
          this.editableTabs.push({
            title: "地址" + (index + 1),
            name: index,
          });
          options.forEach((arr) => {
            if (arr.country === item.country) {
              this.stateOptions[index] = arr.zones;
            }
          });
        });
      }
      this.customerDialogVisible = true;
    },
    addAddressTab() {
      this.editableTabs.push({
        title: "地址" + (this.tabIndex + 1),
        name: this.tabIndex,
      });
      this.customerAddress.push({
        first_name: "",
        last_name: "",
        company: "",
        address: "",
        state_province: "",
        city: "",
        country: "",
        postcode: "",
        phone: "",
        is_default: 1,
      });
      this.editableTabsValue = this.tabIndex;
      this.tabIndex++;
    },
    removeTab(targetName) {
      console.log(targetName);
      const tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
    },
    async editCustomer() {
      let index = [];
      this.editableTabs.forEach((tab) => {
        if (tab.name !== "customer_info") {
          index.push(tab.name);
        }
      });
      let address_lists = [];
      index.forEach((num) => {
        address_lists.push(this.customerAddress[num]);
      });
      try {
        await storeUser({
          user_id: this.customersInfoForm.id,
          name: this.customersInfoForm.username,
          email: this.customersInfoForm.email,
          status: this.customersInfoForm.status,
          phone: this.customersInfoForm.phone,
          subscribe_email: this.customersInfoForm.subscribe_email,
          address_lists: JSON.stringify(address_lists),
        });
        ElMessage({ message: "保存成功", type: "success" });
      } catch (error) {
        console.log(error);
      }
      this.customerDialogVisible = false;
      this.getCustomerList();
    },
  },
};
</script>
<style lang="less" scoped>
.el-card:first-child {
  margin-bottom: 20px;
}
.text-center {
  text-align: center;
}

.dialog-content {
  padding: 0 2rem;
}
</style>
<style lang="less">
.customer-edit-dialog {
  height: 70%;
  .customer-edit-from {
    width: 80%;
  }
  .el-dialog__footer {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
</style>
