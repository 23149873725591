<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <transition
        :duration="{ enter: 800, leave: 100 }"
        name="el-fade-in-linear"
        mode="out-in"
      >
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  height: 100%;
  width: 100%;
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  background-color: #f0f3f6;
  font-size: 14px;
}
/* img {
  width: 100%;
  object-fit: cover;
} */
</style>
