<template>
  <div>
    <!-- 订单列表 -->
    <el-card>
      <el-button type="primary" @click="addAdmin()">添加管理员</el-button>
      <el-table :data="adminList" style="width: 100%">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" sortable width="70" />
        <el-table-column prop="admin_username" label="用户名" min-width="120" />
        <el-table-column prop="status" label="用户状态" min-width="100">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 2" type="warning">禁用</el-tag>
            <el-tag v-if="scope.row.status === 1" type="success">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="admin_group_id" label="权限组" min-width="150" />
        <el-table-column prop="created_at" label="创建时间" min-width="150" />
        <el-table-column fixed="right" label="操作" width="150">
          <template #default="scope">
            <el-button type="primary" @click="editAdmin(scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              title="此操作将永久删除该信息, 是否继续?"
              confirmButtonText="确认"
              cancelButtonText="取消"
              cancelButtonType="default"
              :icon="WarningFilled"
              @confirm="deleteAdmin(scope.row)"
            >
              <template #reference>
                <el-button type="danger" :icon="Delete" />
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
        <template #empty>
          <div style="margin: 50px 0">
            <el-empty v-if="showEmpty" description="无数据" />
          </div>
        </template>
      </el-table>
      <div style="padding-top: 15px">
        <el-pagination
          layout="total, prev, pager, next"
          :current-page="page.pageNum"
          :page-size="page.pageSize"
          :total="page.total"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChangePrev"
          @next-click="handleCurrentChangeNext"
          background
        />
      </div>
    </el-card>
    <!-- 添加管理员弹窗 -->
    <el-dialog v-model="addAdminDialog" title="添加管理员" width="50%">
      <el-form
        :model="adminInfoForm"
        :rules="rules"
        ref="adminInfoForm"
        label-width="100px"
      >
        <el-form-item label="客户名称" prop="admin_username">
          <el-input v-model="adminInfoForm.admin_username" />
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="adminInfoForm.password"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="确认" prop="check_password">
          <el-input
            v-model="adminInfoForm.check_password"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select
            v-model="adminInfoForm.status"
            placeholder="请选择用户状态"
          >
            <el-option label="启用" :value="1" />
            <el-option label="禁用" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="权限组" prop="admin_group_id">
          <el-input v-model="adminInfoForm.admin_group_id" />
          <!-- <el-select
            v-model="adminInfoForm.permission"
            placeholder="请选择权限组"
          >
            <el-option label="超级管理员" value="super_admin" />
            <el-option label="管理员" value="admin" />
          </el-select> -->
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancal">取消</el-button>
          <el-button type="primary" @click="addAdminInfo()">添加</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑管理员弹窗 -->
    <el-dialog v-model="editAdminDialog" title="编辑管理员" width="50%">
      <el-form
        :model="editAdminInfoForm"
        :rules="editRules"
        label-width="100px"
      >
        <el-form-item label="客户名称">
          <el-input v-model="editAdminInfoForm.admin_username" />
        </el-form-item>
        <el-form-item label="重置密码" prop="password">
          <el-input
            v-model="editAdminInfoForm.password"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="check_password">
          <el-input
            v-model="editAdminInfoForm.check_password"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select
            v-model="editAdminInfoForm.status"
            placeholder="请选择用户状态"
          >
            <el-option label="启用" :value="1" />
            <el-option label="禁用" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="权限组" prop="admin_group_id">
          <el-input
            v-model="editAdminInfoForm.admin_group_id"
            autocomplete="off"
          />
          <!-- <el-select
            v-model="editAdminInfoForm.admin_group_id"
            placeholder="请选择权限组"
          >
            <el-option label="超级管理员" value="super_admin" />
            <el-option label="管理员" value="admin" />
          </el-select> -->
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancalEditAdmin">取消</el-button>
          <el-button type="primary" @click="editAdminInfo()">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script scope>
import { Delete, WarningFilled } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import {
  delAdmin,
  editAdmin,
  getAdminList,
  registerAdmin,
} from "../../axios/api";
export default {
  name: "AdminList",
  components: {},
  setup() {
    return { Delete, WarningFilled };
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.adminInfoForm.re_pwd !== "") {
          this.$refs.adminInfoForm.validateField("re_pwd");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.adminInfoForm.password) {
        callback(new Error("两次密码不相同！"));
      } else {
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.editAdminInfoForm.new_password) {
        callback(new Error("两次密码不相同！"));
      } else {
        callback();
      }
    };
    var rules = {
      password: [
        { required: true, message: "请输入密码", trigger: "blur" },
        { validator: validatePass, trigger: "blur" },
      ],
      re_pwd: [
        { required: true, message: "请确认密码", trigger: "blur" },
        { validator: validatePass2, trigger: "blur" },
      ],
    };
    var editRules = {
      new_password: [{ validator: validatePass, trigger: "blur" }],
      re_pwd: [{ validator: validatePass3, trigger: "blur" }],
    };
    return {
      adminList: [],
      addAdminDialog: false,
      editAdminDialog: false,
      showEmpty: false,
      page: {
        total: 0,
        pageNum: 1,
        pageSize: 50,
      },
      adminInfoForm: {
        admin_username: "",
        password: "",
        check_password: "",
        admin_group_id: "",
        status: 1,
      },
      editAdminInfoForm: {
        adminid: 0,
        admin_username: "",
        password: "",
        check_password: "",
        admin_group_id: "",
        status: "1",
      },
      rules,
      editRules,
    };
  },
  mounted() {
    this.getAdminList();
  },
  methods: {
    async getAdminList() {
      let params = {
        page: this.page.pageNum,
        limit: this.page.pageSize,
      };
      const data = await getAdminList({ params });
      this.adminList = data.data;
      if (this.adminList.length === 0) {
        this.showEmpty = true;
      }
    },
    addAdmin() {
      this.addAdminDialog = true;
    },
    editAdmin(item) {
      this.editAdminDialog = true;
      this.editAdminInfoForm.adminid = item.id;
      this.editAdminInfoForm.admin_username = item.admin_username;
      this.editAdminInfoForm.status = item.status;
      this.editAdminInfoForm.admin_group_id = item.admin_group_id;
    },
    async deleteAdmin(item) {
      try {
        await delAdmin({ id: item.id });
        this.getAdminList();
        ElMessage({ message: "删除成功", type: "success" });
      } catch (error) {
        console.log(error);
      }
      console.log(item);
    },
    handleCurrentChangePrev(val) {
      this.pageNum = val;
      console.log(`上一页: ${val}`);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrderList();
      console.log(`当前页: ${val}`);
    },
    handleCurrentChangeNext(val) {
      this.pageNum = val;
      console.log(`下一页: ${val}`);
    },
    cancal() {
      this.addAdminDialog = false;
    },
    async addAdminInfo() {
      try {
        await registerAdmin(this.adminInfoForm);
        this.addAdminDialog = false;
        this.getAdminList();
        ElMessage({ message: "添加成功", type: "success" });
      } catch (error) {
        console.log(error);
      }
    },
    cancalEditAdmin() {
      this.editAdminDialog = false;
    },
    async editAdminInfo() {
      try {
        await editAdmin(this.editAdminInfoForm);
        this.editAdminDialog = false;
        this.getAdminList();
        ElMessage({ message: "修改成功", type: "success" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-table {
  height: calc(100vh - 251px);
  margin-top: 1rem;
}
</style>
