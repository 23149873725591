<template>
  <div class="container">
    <!-- 商品查询 -->
    <el-card>
      <el-form
        :inline="true"
        :model="query"
        ref="query"
        class="goods_query_form"
      >
        <el-form-item label="商品ID" prop="id">
          <el-input v-model.number="query.id" placeholder="商品ID" />
        </el-form-item>
        <el-form-item label="商品标题" prop="goods_name">
          <el-input v-model="query.goods_name" placeholder="商品标题" />
        </el-form-item>
        <el-form-item label="商品分类" prop="category_id">
          <el-cascader
            v-model="query.category_id"
            :options="categoryOption"
            @focus="getCategoryOption"
            placeholder="请选择"
            clearable
          />
        </el-form-item>
        <el-form-item label="商品状态" prop="status">
          <el-select v-model="query.status" placeholder="商品状态">
            <el-option label="已上架" value="1"></el-option>
            <el-option label="未上架" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :icon="Search" @click="getGoodsList"
            >查询</el-button
          >
          <el-button type="primary" :icon="Brush" @click="resetForm" />
          <el-button type="primary" :icon="Plus" @click="add">商品</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 商品列表 -->
    <el-card class="table">
      <el-table
        :data="goodsList"
        :height="tableHeight"
        style="width: 100%"
        class="data_table"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" sortable width="70" />
        <el-table-column prop="thumb" label="图片">
          <template #default="scope">
            <div style="display: inline-flex">
              <el-image class="goods_image" :src="scope.row.thumb" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="goods_name"
          label="商品名称"
          min-width="240"
          sortable
        />
        <el-table-column prop="price" label="价格" sortable />
        <el-table-column prop="stock" label="库存" />
        <el-table-column prop="sales_num" label="销量" sortable />
        <el-table-column prop="status" label="状态">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 1">已上架</el-tag>
            <el-tag v-if="scope.row.status === 2" type="success">未上架</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          prop="created_at"
          label="创建时间"
          min-width="200"
          sortable
        >
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-icon>
                <timer />
              </el-icon>
              <span style="margin-left: 10px">{{ scope.row.created_at }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label=" 操作" width="200">
          <template #default="scope">
            <el-button :icon="Edit" @click="edit(scope.$index, scope.row)" />
            <el-button
              v-if="scope.row.status === 2"
              type="primary"
              @click="changeStatus(1, scope.row)"
              >上架
            </el-button>
            <el-button
              v-if="scope.row.status === 1"
              type="warning"
              @click="changeStatus(2, scope.row)"
              >下架
            </el-button>
            <el-popconfirm
              title="此操作将删除该产品数据, 是否继续?"
              confirmButtonText="确认"
              cancelButtonText="取消"
              cancelButtonType="default"
              :icon="WarningFilled"
              @confirm="deleteGoods(scope.row)"
            >
              <template #reference>
                <el-button type="danger" :icon="Delete" />
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
        <template #empty>
          <div style="margin: 50px 0">
            <el-empty v-if="showEmpty" description="暂时还没有商品哦" />
          </div>
        </template>
      </el-table>
      <div style="padding-top: 15px">
        <el-pagination
          layout="total, prev, pager, next"
          :current-page="pageNum"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChangePrev"
          @next-click="handleCurrentChangeNext"
          background
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  ArrowDown,
  ArrowUp,
  Brush,
  Delete,
  Edit,
  Plus,
  Search,
  Timer,
  WarningFilled,
} from "@element-plus/icons-vue";
// import { ElMessage } from 'element-plus'
// import container from "../../components/container";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import {
  delGood,
  getCategorylists,
  getGoodslist,
  putonGoods,
} from "../../axios/api";

export default {
  name: "Goods",
  components: { Timer },
  setup() {
    const tableHeight = ref("0px");

    return {
      Search,
      Brush,
      Plus,
      Edit,
      ArrowUp,
      ArrowDown,
      Delete,
      WarningFilled,
      tableHeight,
    };
  },
  data() {
    return {
      // 查询条件
      query: {
        id: "",
        goods_name: "",
        category_id: "",
        status: "",
      },

      // 类目选项
      categoryOption: [],

      // 商品
      goodsList: [],
      goods: {
        id: "",
        category_id: "",
        goods_name: "",
        description: "",
        name: "",
        price: "",
        stock: "",
        thumb: "",
        remark: "",
        status: "",
      },

      rules: {
        category_id: {
          required: true,
          message: "请选择一个类目",
          trigger: "blur",
        },
        goods_name: {
          required: true,
          message: "请输入一个标题",
          trigger: "blur",
        },
        name: { required: true, message: "请输入一个名称", trigger: "blur" },
        price: [
          { required: true, message: "价格不能为空", trigger: "blur" },
          { type: "number", message: "价格必须为数字", trigger: "blur" },
        ],
        stock: [
          { required: true, message: "价格不能为空", trigger: "blur" },
          { type: "number", message: "价格必须为数字", trigger: "blur" },
        ],
        thumb: {
          required: true,
          message: "至少上传一张图片",
          trigger: "blur",
        },
      },

      // 图片列表
      pictureList: [],

      // 分页
      total: 0,
      pageNum: 1,
      pageSize: 50,

      // 请求认证
      // token: "",

      // 空状态
      showEmpty: false,
    };
  },
  mounted() {
    this.getGoodsList();
    this.goodsDialogVisible = this.$route.params.dialog;
    const extraHeight =
      document.querySelector(".goods_query_form").offsetHeight;
    const mianHeight = document.querySelector(".main").offsetHeight;
    this.tableHeight = `${mianHeight - extraHeight - 171}px`;
  },
  methods: {
    // 分页，处理函数
    handleCurrentChangePrev(val) {
      this.pageNum = val;
      console.log(`上一页: ${val}`);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getGoodsList();
      console.log(`当前页: ${val}`);
    },
    handleCurrentChangeNext(val) {
      this.pageNum = val;
      console.log(`下一页: ${val}`);
    },

    // 添加、编辑商品
    add() {
      this.$router.push("/product/add");
    },
    edit(index, row) {
      this.$router.push({
        path: "/product/edit",
        query: { product_id: row.id },
      });
    },

    // 获取商品类目选项
    async getCategoryOption() {
      try {
        let params = {
          limit: 100,
          page: 1,
        };
        const data = await getCategorylists({ params });
        let options = [];
        data.data.forEach((element) => {
          // if (element.children_id !== null) {
          //   let params = { id: parseInt(element.children_id) };
          //   const secondData = getSecondCategorylists({ params });
          // }
          options.push({ value: element.id, label: element.category_name });
          this.categoryOption = options;
        });
      } catch (error) {
        console.log(error);
      }
    },

    // 获取商品列表
    async getGoodsList() {
      try {
        this.query.page = this.pageNum;
        this.query.limit = this.pageSize;
        let params = this.query;
        const data = await getGoodslist({ params });
        this.goodsList = data.data;
        this.total = data.count;
      } catch (error) {
        console.log(error);
      }
      if (this.goodsList.length === 0) {
        this.showEmpty = true;
      }
    },

    // 商品上架、下架
    async changeStatus(status, row) {
      try {
        await putonGoods({ status: status, goods_id: row.id });
        ElMessage({ message: "修改成功", type: "success" });
        this.getGoodsList();
      } catch (error) {
        console.log(error);
      }
    },

    // 删除商品
    async deleteGoods(row) {
      try {
        await delGood({ id: row.id });
        ElMessage({ message: "删除成功", type: "success" });
        this.getGoodsList();
      } catch (error) {
        console.log(error);
      }
    },

    // 重置表单
    resetForm() {
      this.$refs["query"].resetFields();
      // this.getGoodsList()
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  height: 100%;
}
.table {
  margin-top: 20px;
}

.goods_image_box {
  margin: 4px 5px 5px 0;
}

.goods_image_box,
.goods_image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.goods_title {
  font-size: 13px;
  font-weight: 450;
  margin: 2px 3px 1px;
}

.goods_id {
  margin: 0 3px;
  font-size: 12px;
  font-weight: 450;
}

.goods_image_upload_icon {
  color: #9aa9b9;
  font-size: 24px;
  font-weight: 200;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.el-dialog {
  border-radius: 10px !important;
}

.el-upload {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
}

.el-upload--picture-card {
  background-color: #f2f4f7;
  border: none;
}

.el-upload--picture-card {
  width: 100px;
  height: 100px;
  margin-top: 0;
  font-size: 16px !important;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
}

.el-upload-list--picture-card .el-upload-list__item-thumbnail {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 16px;
}

.el-table__header tr,
.el-table__header th {
  background-color: #eff6ff !important;
  font-weight: 450 !important;
  color: #627a94 !important;
  border-bottom: none !important;
}
</style>
