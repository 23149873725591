<template>
  <div>
    <!-- 商品查询 -->
    <el-card>
      <el-form
        :inline="true"
        :model="query"
        ref="query"
        class="goods_query_form"
      >
        <el-form-item prop="name">
          <el-input v-model.number="query.name" placeholder="类目名称" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :icon="Search" @click="getCategory"
            >查询</el-button
          >
          <el-button type="primary" @click="addLv1Category"
            >添加一级类目</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <!-- 商品一级类目列表 -->
      <el-table :data="categoryLv1List" height="65vh" style="width: 100%">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="编号" />
        <el-table-column prop="category_name" label="名称" />
        <el-table-column label="级别">1</el-table-column>
        <el-table-column prop="sort" label="排序" />
        <el-table-column label="操作" width="320">
          <template #default="scope">
            <el-button
              type="primary"
              :icon="Edit"
              @click="editLv1Category(scope.row)"
            />
            <el-button
              type="primary"
              :icon="Plus"
              @click="addLv2Category(scope.row)"
            />
            <el-button type="primary" @click="checkLv2Category(scope.row)"
              >查看二级类目</el-button
            >
            <el-popconfirm
              title="此操作将永久删除该信息, 是否继续?"
              confirmButtonText="确认"
              cancelButtonText="取消"
              cancelButtonType="default"
              :icon="WarningFilled"
              @confirm="deleteCategory(scope.row)"
            >
              <template #reference>
                <el-button type="danger" :icon="Delete" />
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
        <template #empty>
          <div style="margin: 50px 0">
            <el-empty v-if="showEmpty" description="暂时还没有数据哦" />
          </div>
        </template>
      </el-table>
      <div style="padding: 10px 0">
        <el-pagination
          layout="total, prev, pager, next"
          :current-page="pageNum"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChangePrev"
          @next-click="handleCurrentChangeNext"
          background
        />
      </div>
      <!-- 添加、编辑商品，通用对话框 -->
      <el-dialog
        :title="dialogTitle"
        v-model="categoryDialogVisible"
        top="30vh"
        width="35%"
        @close="cancelCategory"
      >
        <el-form
          :model="category_form"
          label-width="50px"
          style="padding: 10px"
        >
          <el-form-item label="名称">
            <el-input
              v-model="category_form.category_name"
              type="text"
              maxlength="10"
              show-word-limit
            />
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model.number="category_form.sort" />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelCategory">取消</el-button>
            <el-button type="primary" @click="confirmCategory">确定</el-button>
          </span>
        </template>
      </el-dialog>
      <!-- 查看二级类目列表，对话框 -->
      <el-dialog
        :title="dialogTitle"
        v-model="checkLv2CategoryDialogVisible"
        top="10vh"
        width="50%"
      >
        <el-table :data="categoryLv2List" height="60vh" style="width: 100%">
          <el-table-column prop="id" label="编号" />
          <el-table-column prop="category_name" label="名称" />
          <el-table-column label="级别">2</el-table-column>
          <el-table-column prop="sort" label="排序" />
          <el-table-column label="操作" min-width="130">
            <template #default="scope">
              <el-button
                type="primary"
                :icon="Edit"
                @click="editLv2Category(scope.row)"
              />
              <el-popconfirm
                title="此操作将永久删除该信息, 是否继续?"
                confirmButtonText="确认"
                cancelButtonText="取消"
                cancelButtonType="default"
                :icon="WarningFilled"
                @confirm="deleteCategory(scope.row)"
              >
                <template #reference>
                  <el-button type="danger" :icon="Delete" />
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {
  Delete,
  Edit,
  Plus,
  Search,
  WarningFilled,
} from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import {
  addCategory,
  delCategory,
  getCategorylists,
  getSecondCategorylists,
  storeCategory,
} from "../../axios/api";
export default {
  name: "Category",
  components: {},
  setup() {
    return { Search, Plus, Edit, Delete, WarningFilled };
  },
  data() {
    return {
      categoryLv1List: [],
      categoryLv2List: [],
      category_form: {
        id: 0,
        category_name: "",
        parent_id: 1,
        category: 1,
        sort: "",
      },
      query: {
        name: "",
      },
      dialogTitle: "",
      operateType: "",
      categoryDialogVisible: false,
      checkLv2CategoryDialogVisible: false,

      // 分页
      total: 0,
      pageNum: 1,
      pageSize: 50,

      // 空状态
      showEmpty: false,
    };
  },
  mounted() {
    this.getCategory();
  },
  methods: {
    // 分页，处理函数
    handleCurrentChangePrev(val) {
      this.pageNum = val;
      console.log(`上一页: ${val}`);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getCategory();
      console.log(`当前页: ${val}`);
    },
    handleCurrentChangeNext(val) {
      this.pageNum = val;
      console.log(`下一页: ${val}`);
    },

    // 添加一级类目
    addLv1Category() {
      this.operateType = "add";
      this.dialogTitle = "添加一级类目";
      this.category_form.category = 1;
      this.category_form.parent_id = null;
      this.categoryDialogVisible = true;
    },

    // 编辑一级类目
    editLv1Category(row) {
      this.operateType = "edit";
      this.dialogTitle = "编辑一级类目";
      this.category_form.id = row.id;
      this.category_form.category = 1;
      this.category_form.category_name = row.category_name;
      this.category_form.sort = row.sort;
      this.categoryDialogVisible = true;
    },

    // 添加二级类目
    addLv2Category(row) {
      this.operateType = "add";
      this.dialogTitle = "添加二级类目";
      this.category_form.category = 2;
      this.category_form.parent_id = row.id;
      this.categoryDialogVisible = true;
    },

    // 编辑二级类目
    editLv2Category(row) {
      this.operateType = "edit";
      this.dialogTitle = "编辑二级类目";
      this.category_form.category = 2;
      this.category_form.id = row.id;
      this.category_form.category_name = row.category_name;
      this.category_form.sort = row.sort;
      this.categoryDialogVisible = true;
    },

    // 查看二级类目
    async checkLv2Category(row) {
      let params = { id: row.id, limit: this.pageSize, page: this.pageNum };
      const data = await getSecondCategorylists({ params });
      this.category_form.parent_id = row.id;
      this.categoryLv2List = data.data;
      this.dialogTitle = "查看二级类目";
      this.checkLv2CategoryDialogVisible = true;
    },

    // 获取类目列表
    async getCategory() {
      try {
        let params = {
          category_name: this.query.name,
          limit: this.pageSize,
          page: this.pageNum,
        };
        const data = await getCategorylists({ params });
        this.categoryLv1List = data.data;
        this.total = data.count;
        if (this.categoryLv1List.length === 0) {
          this.showEmpty = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 确定添加、编辑类目
    async confirmCategory() {
      if (this.operateType === "add") {
        try {
          if (this.category_form.category === 1) {
            await addCategory({
              category_name: this.category_form.category_name,
              category: 1,
              sort: this.category_form.sort,
            });
          } else {
            await addCategory({
              category_name: this.category_form.category_name,
              category: 2,
              sort: this.category_form.sort,
              parent_id: this.category_form.parent_id,
            });
          }
          ElMessage({ message: "添加成功", type: "success" });
          this.categoryDialogVisible = false;
          this.getCategory();
        } catch (error) {
          console.log(error);
        }
      } else {
        if (this.category_form.category === 1) {
          await storeCategory({
            id: this.category_form.id,
            category_name: this.category_form.category_name,
            category: 1,
            sort: this.category_form.sort,
          });
        } else {
          await storeCategory({
            id: this.category_form.id,
            category_name: this.category_form.category_name,
            category: 2,
            sort: this.category_form.sort,
            parent_id: this.category_form.parent_id,
          });
          this.checkLv2Category({ id: this.category_form.parent_id });
        }
        this.categoryDialogVisible = false;
        this.getCategory();
        ElMessage({ message: "修改成功", type: "success" });
      }
    },
    async deleteCategory(row) {
      try {
        await delCategory({ id: row.id });
        ElMessage({ message: "删除成功", type: "success" });
        this.getCategory();
        this.checkLv2CategoryDialogVisible = false;
      } catch (error) {
        console.log(error);
      }
    },
    cancelCategory() {
      this.categoryDialogVisible = false;
      this.emptyCategory();
      this.dialogTitle = "";
      this.operateType = "";
    },

    // 清空类目
    emptyCategory() {
      this.category_form.category_name = "";
      this.category_form.sort = "";
    },
  },
};
</script>

<style scoped>
.el-card {
  margin-bottom: 20px;
}
.el-dialog {
  height: 200px !important;
  overflow-y: scroll;
}
</style>
