<template>
  <div>
    <!-- 配送查询 -->
    <el-card>
      <el-form
        :inline="true"
        :model="query"
        label-width="80px"
        ref="query"
        class="query_form"
      >
        <el-form-item label="配送方式" prop="shipping_type_name">
          <el-input v-model="query.shipping_type_name" />
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" :icon="Search" @click="getShippingList"
            >查询</el-button
          >
          <el-button type="primary" :icon="Brush" @click="resetForm" />
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 运费列表 -->
    <el-card>
      <el-button type="primary" @click="openShippingialog('add')"
        >添加运费</el-button
      >
      <el-table :data="shippingList" :height="tableHeight" style="width: 100%">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" width="55" />
        <el-table-column
          prop="shipping_type_name"
          label="配送方式"
          min-width="180"
        />
        <el-table-column
          prop="shipping_address_id"
          label="地区"
          min-width="120"
        >
          <template #default="scope">
            <div v-if="scope.row.shipping_address_id === 1">亚洲</div>
            <div v-if="scope.row.shipping_address_id === 2">欧洲</div>
            <div v-if="scope.row.shipping_address_id === 3">非洲</div>
            <div v-if="scope.row.shipping_address_id === 4">大洋洲</div>
            <div v-if="scope.row.shipping_address_id === 5">北美洲</div>
            <div v-if="scope.row.shipping_address_id === 6">南美洲</div>
          </template>
        </el-table-column>
        <el-table-column prop="shipping_price" label="运费" min-width="150" />
        <el-table-column prop="status" label="状态" min-width="100">
          <template #default="scope">
            <el-tag v-if="scope.row.status === 2" type="danger">禁用</el-tag>
            <el-tag v-if="scope.row.status === 1" type="success">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="operation_type" label="状态" min-width="150">
          <template #default="scope">
            <el-tag v-if="scope.row.operation_type === 1" type="info"
              >固定运费</el-tag
            >
            <el-tag v-if="scope.row.operation_type === 2" type="info"
              >按件数运算</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template #default="scope">
            <el-button
              type="primary"
              @click="openShippingialog('edit', scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              title="确认删除该条数据?"
              confirmButtonText="确认"
              cancelButtonText="取消"
              cancelButtonType="default"
              :icon="WarningFilled"
              @confirm="deleteShipping(scope.row)"
            >
              <template #reference>
                <el-button type="danger" :icon="Delete" />
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
        <template #empty>
          <div style="margin: 50px 0">
            <el-empty v-if="showEmpty" description="暂时还没有数据哦" />
          </div>
        </template>
      </el-table>
      <div style="padding-top: 15px">
        <el-pagination
          layout="total, prev, pager, next"
          :current-page="pageNum"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChangePrev"
          @next-click="handleCurrentChangeNext"
          background
        />
      </div>
    </el-card>
    <!-- 添加运费弹窗 -->
    <el-dialog :title="dialogTitle" v-model="shippingialog" width="50%">
      <el-form :model="shippingForm" ref="shippingForm" label-width="100px">
        <el-form-item label="配送方式" prop="shipping_type_name">
          <el-input v-model="shippingForm.shipping_type_name" />
        </el-form-item>
        <el-form-item label="地区">
          <el-select
            v-model="shippingForm.shipping_address_id"
            placeholder="请选择"
          >
            <el-option
              v-for="item in areaListsOption"
              :key="item.id"
              :label="item.country_group"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="运费" prop="shipping_price">
          <el-input
            type="number"
            min="0"
            v-model="shippingForm.shipping_price"
          />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="shippingForm.status" placeholder="请选择运费状态">
            <el-option label="启用" :value="1" />
            <el-option label="禁用" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="运算方式" prop="operation_type">
          <el-select
            v-model="shippingForm.operation_type"
            placeholder="请选择运算方式"
          >
            <el-option label="固定运费" :value="1" />
            <el-option label="按件数运算" :value="2" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancal">取消</el-button>
          <el-button type="primary" @click="changeShipping()">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { Brush, Delete, Search, WarningFilled } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import {
  addShippingSetup,
  delShipping,
  editShipping,
  getAreaList,
  getShippingInfo,
  getShippinglist,
} from "../../axios/api";
export default {
  name: "Shipping",
  components: {},
  setup() {
    const tableHeight = ref("0px");
    const ShippingOptions = [
      {
        value: 2,
        label: "禁用",
      },
      {
        value: 1,
        label: "启用",
      },
    ];
    const areaListsOption = ref([]);
    getArea();
    async function getArea() {
      const data = await getAreaList();
      areaListsOption.value = data.data;
    }
    return {
      Brush,
      Search,
      Delete,
      WarningFilled,
      ShippingOptions,
      tableHeight,
      areaListsOption,
    };
  },
  data() {
    return {
      // 分页
      total: 0,
      pageNum: 1,
      pageSize: 50,

      showEmpty: false,
      shippingialog: false,
      dialogTitle: "添加运费",
      dialogType: "add",

      query: { shipping_type_name: "" },
      shippingList: [],
      shippingForm: {
        shipping_type_name: "",
        shipping_price: 0,
        shipping_address_id: 1,
        status: 1,
        operation_type: 1,
      },
    };
  },
  mounted() {
    this.getShippingList();
    const extraHeight = document.querySelector(".query_form").offsetHeight;
    const mianHeight = document.querySelector(".main").offsetHeight;
    this.tableHeight = `${mianHeight - extraHeight - 181}px`;
  },
  methods: {
    // 重置表单
    resetForm() {
      this.$refs["query"].resetFields();
    },

    async getShippingList() {
      try {
        this.query.page = this.pageNum;
        this.query.limit = this.pageSize;
        let params = this.query;
        const data = await getShippinglist({ params });
        this.shippingList = data.data;
        this.total = data.count ?? 0;
        if (this.shippingList.length === 0) {
          this.showEmpty = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 分页，处理函数
    handleCurrentChangePrev(val) {
      this.pageNum = val;
      console.log(`上一页: ${val}`);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      console.log(`当前页: ${val}`);
    },
    handleCurrentChangeNext(val) {
      this.pageNum = val;
      console.log(`下一页: ${val}`);
    },

    async openShippingialog(type, item) {
      if (type === "add") {
        this.dialogTitle = "添加运费";
        this.dialogType = "add";
      } else {
        this.dialogTitle = "修改运费";
        this.dialogType = "edit";
        let params = { id: item.id };
        const data = await getShippingInfo({ params });
        this.shippingForm = data.data[0];
      }
      this.shippingialog = true;
    },

    // 添加运费
    async changeShipping() {
      if (this.dialogType === "add") {
        try {
          await addShippingSetup(this.shippingForm);
          this.shippingialog = false;
          ElMessage({ message: "添加成功", type: "success" });
          this.getShippingList();
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await editShipping({
            id: this.shippingForm.id,
            shipping_type_name: this.shippingForm.shipping_type_name,
            shipping_address_id: this.shippingForm.shipping_address_id,
            shipping_price: this.shippingForm.shipping_price,
            status: this.shippingForm.status,
            operation_type: this.shippingForm.operation_type,
          });
          this.shippingialog = false;
          ElMessage({ message: "修改成功", type: "success" });
          this.getShippingList();
        } catch (error) {
          console.log(error);
        }
        console.log(this.shippingForm);
      }
    },

    async deleteShipping(item) {
      try {
        await delShipping({ id: item.id });
        ElMessage({ message: "删除成功", type: "success" });
        this.getShippingList();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-card:first-child {
  margin-bottom: 20px;
}
</style>
