<template>
  <div>
    <!-- 订单查询 -->
    <el-card>
      <el-form
        :inline="true"
        :model="orderQuery"
        label-width="80px"
        ref="orderQuery"
        class="query_form"
      >
        <el-col :span="24">
          <el-form-item label="订单ID" prop="id">
            <el-input v-model.number="orderQuery.id" placeholder="订单ID" />
          </el-form-item>
          <el-form-item label="订单号" prop="order_num">
            <el-input
              v-model.number="orderQuery.order_num"
              placeholder="订单号"
            />
          </el-form-item>
          <el-form-item label="客户" prop="buyer_name">
            <el-input
              v-model.number="orderQuery.buyer_name"
              placeholder="客户"
            />
          </el-form-item>
          <el-form-item label="总计" prop="order_price">
            <el-input
              v-model.number="orderQuery.order_price"
              placeholder="(格式: 10 或 10-20)"
            />
          </el-form-item>
        </el-col>
        <el-form-item label="生成日期">
          <el-col :span="11">
            <el-form-item prop="created_at_start" style="margin-right: unset">
              <el-date-picker
                v-model="orderQuery.created_at_start"
                type="date"
                placeholder="订单生成日期（开始）"
                style="width: 100%"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </el-col>
          <el-col class="text-center" :span="2">
            <span class="text-gray-500">-</span>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="created_at_end">
              <el-date-picker
                v-model="orderQuery.created_at_end"
                type="date"
                placeholder="订单生成日期（结束）"
                style="width: 100%"
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
              />
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-col :span="24">
          <el-form-item label="状态" prop="order_status">
            <el-button-group class="ml-4">
              <el-button
                v-for="item in orderStatusBtns"
                :key="item"
                :class="{ active: orderQuery.order_status === item.value }"
                @click="orderQuery.order_status = item.value"
              >
                {{ item.name }}</el-button
              >
            </el-button-group>
          </el-form-item>
        </el-col>
        <el-form-item label=" ">
          <el-button type="primary" :icon="Search" @click="getOrderList"
            >查询</el-button
          >
          <el-button type="primary" :icon="Brush" @click="resetForm" />
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 订单列表 -->
    <el-card>
      <el-table :data="orderList" :height="tableHeight" style="width: 100%">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" sortable width="70" />
        <el-table-column prop="order_num" label="订单号" width="200" />
        <el-table-column prop="buyer_name" label="客户名称" width="200" />
        <el-table-column prop="pay_type" label="支付方式" width="150">
          <template #default="scope">
            <el-tag v-if="scope.row.pay_type === 0" type="warning"
              >待支付</el-tag
            >
            <el-tag v-if="scope.row.pay_type === 1" type="info">支付宝</el-tag>
            <el-tag v-if="scope.row.pay_type === 2" type="info">微信</el-tag>
            <el-tag v-if="scope.row.pay_type === 3" type="info">信用卡</el-tag>
            <el-tag v-if="scope.row.pay_type === 4" type="info"
              >货到付款</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="order_status" label="订单状态" width="150">
          <template #default="scope">
            <el-tag v-if="scope.row.order_status === 1" type="warning"
              >待支付</el-tag
            >
            <el-tag v-if="scope.row.order_status === 2" type="info"
              >待处理</el-tag
            >
            <el-tag v-if="scope.row.order_status === 3" type="info"
              >已审核</el-tag
            >
            <el-tag v-if="scope.row.order_status === 4" type="info"
              >已取消</el-tag
            >
            <el-tag v-if="scope.row.order_status === 5" type="success"
              >已支付</el-tag
            >
            <el-tag v-if="scope.row.order_status === 6" type="danger"
              >退款中</el-tag
            >
            <el-tag v-if="scope.row.order_status === 7" type="info"
              >支付失败</el-tag
            >
            <el-tag v-if="scope.row.order_status === 8" type="primary"
              >部分发货</el-tag
            >
            <el-tag v-if="scope.row.order_status === 9" type="primary"
              >全部发货</el-tag
            >
            <el-tag v-if="scope.row.status === 10" type="success"
              >已完成</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="order_price" label="总计" width="150" />
        <el-table-column prop="created_at" label="生成日期" width="200" />
        <el-table-column prop="updated_at" label="修改日期" width="200" />
        <el-table-column fixed="right" label="操作" min-width="90">
          <template #default="scope">
            <el-button
              type="primary"
              @click="checkOrderDetails(scope.$index, scope.row)"
              >详情</el-button
            >
            <!-- <el-button v-if="scope.row.status === 3"  type="primary" @click="modifyOrder(scope.row, 4)">配送</el-button>
                        <el-popconfirm title="此操作将永久删除该信息, 是否继续?" confirmButtonText="确认" cancelButtonText="取消" cancelButtonType="default" v-if="scope.row.status === 2 || scope.row.status === 5"
                          :icon="WarningFilled" @confirm="deleteOrder(scope.row)">
                          <template #reference>
                            <el-button  type="danger">删除</el-button>
                          </template>
                        </el-popconfirm> -->
          </template>
        </el-table-column>
        <template #empty>
          <div style="margin: 50px 0">
            <el-empty v-if="showEmpty" description="暂时还没有订单哦" />
          </div>
        </template>
      </el-table>
      <div style="padding-top: 15px">
        <el-pagination
          layout="total, prev, pager, next"
          :current-page="pageNum"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChangePrev"
          @next-click="handleCurrentChangeNext"
          background
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  Brush,
  Delete,
  Search,
  Setting,
  WarningFilled,
} from "@element-plus/icons-vue";
// import {ElMessage} from "element-plus";
import { ref } from "vue";
import { getOrderLists } from "../../axios/api";

export default {
  name: "Order",
  components: {},
  setup() {
    const tableHeight = ref("0px");
    return { Search, Brush, Setting, Delete, WarningFilled, tableHeight };
  },
  data() {
    return {
      orderQuery: {
        id: "",
        order_num: "",
        buyer_name: "",
        order_price: "",
        created_at_start: "",
        created_at_end: "",
        order_status: "0",
      },

      orderStatusBtns: [
        { name: "全部", value: "" },
        { name: "待支付", value: 1 },
        { name: "待处理", value: 2 },
        { name: "已审核", value: 3 },
        { name: "已取消", value: 4 },
        { name: "已支付", value: 5 },
        { name: "退款中", value: 6 },
        { name: "支付失败", value: 7 },
        { name: "部分发货", value: 8 },
        { name: "全部发货", value: 9 },
        { name: "已完成", value: 10 },
      ],
      orderList: [],

      // 分页
      total: 0,
      pageNum: 1,
      pageSize: 50,

      // 空状态
      showEmpty: false,
    };
  },
  mounted() {
    this.getOrderList();
    const extraHeight = document.querySelector(".query_form").offsetHeight;
    const mianHeight = document.querySelector(".main").offsetHeight;
    this.tableHeight = `${mianHeight - extraHeight - 181}px`;
  },
  methods: {
    // 重置表单
    resetForm() {
      this.$refs["orderQuery"].resetFields();
      this.getOrderList();
    },

    // 分页，处理函数
    handleCurrentChangePrev(val) {
      this.pageNum = val;
      console.log(`上一页: ${val}`);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrderList();
      console.log(`当前页: ${val}`);
    },
    handleCurrentChangeNext(val) {
      this.pageNum = val;
      console.log(`下一页: ${val}`);
    },

    // 获取订单列表
    async getOrderList() {
      try {
        this.orderQuery.page = this.pageNum;
        this.orderQuery.limit = this.pageSize;
        this.orderQuery.created_at_start = this.orderQuery.created_at_start
          ? this.orderQuery.created_at_start + " 00:00:00"
          : "";
        this.orderQuery.created_at_end = this.orderQuery.created_at_end
          ? this.orderQuery.created_at_end + " 00:00:00"
          : "";
        let params = this.orderQuery;
        const data = await getOrderLists({ params });
        this.orderList = data.data;
        this.total = data.count;
        if (this.orderList.length === 0) {
          this.showEmpty = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    // 查看订单详情
    checkOrderDetails(index, row) {
      this.$router.push({
        path: "/order/details",
        query: { order_id: row.id },
      });
    },

    // 删除订单
    deleteOrder(row) {
      console.log(row);
      /* this.$axios.delete('/order/delete', {
          params: {
            id: row.id
          }
        }).then((response) => {
          if (response.data.code === 200) {
            ElMessage({message: response.data.message, type: 'success'})
          }
          this.getOrderList()
        }).catch((error) => {
          console.log(error)
      }) */
    },
  },
};
</script>

<style lang="less" scoped>
.el-card:first-child {
  margin-bottom: 20px;
}

.goods_image {
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.el-dialog {
  border-radius: 10px !important;
}

.el-table__header tr,
.el-table__header th {
  background-color: #eff6ff !important;
  font-weight: 450 !important;
  color: #627a94 !important;
  border-bottom: none !important;
}

.text-center {
  text-align: center;
}

.el-button-group {
  .active {
    color: var(--el-button-hover-text-color);
    border-color: var(--el-color-primary);
    background-color: var(--el-button-hover-bg-color);
    z-index: 1;
  }
}
</style>
