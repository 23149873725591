<template>
  <div>
    <el-page-header @back="goBack"></el-page-header>
    <el-card>
      <el-tabs v-model="localeActiveName" class="add-product">
        <el-tab-pane label="English" name="en">
          <el-form-item label="	产品名称" label-width="15%">
            <el-input v-model="productData.box_goods_name" />
          </el-form-item>

          <el-form-item label="产品描述" label-width="15%">
            <tinymce
              ref="tinymce"
              :tinymceHtml="productData.description"
            ></tinymce>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="中文" name="zh-cn">
          <el-form-item label="产品名称" label-width="15%">
            <el-input v-model="productData.box_goods_name_cn" />
          </el-form-item>
          <el-form-item label="产品描述" label-width="15%">
            <tinymce
              ref="tinymce_cn"
              :tinymceHtml="productData.description_cn"
            ></tinymce>
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
      <el-form-item label="盲盒分类" label-width="19%">
        <el-select v-model="productData.brand_id" placeholder="请选择">
          <el-option
            v-for="item in categoryOption"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="产品图片" label-width="19%">
        <div class="image-list" v-if="productData.thumb">
          <el-image :src="productData.thumb" />
          <div class="del-image" @click="delProductImage('thumb')">移除</div>
        </div>
        <el-upload
          v-if="!productData.thumb"
          ref="uploadRef"
          v-model:file-list="fileList"
          list-type="picture-card"
          action="#"
          :on-preview="handlePictureCardPreview"
          :auto-upload="false"
          limit="1"
        >
          <el-icon><Plus /></el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="产品缩略图" label-width="19%">
        <div class="image-list" v-if="productData.thumbnail">
          <el-image :src="productData.thumbnail" />
          <div class="del-image" @click="delProductImage('thumbnail')">
            移除
          </div>
        </div>
        <el-upload
          v-if="!productData.thumbnail"
          v-model:file-list="thumbnailFile"
          list-type="picture-card"
          action="#"
          :on-preview="handlePictureCardPreview"
          :auto-upload="false"
          limit="1"
        >
          <el-icon><Plus /></el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="款式" label-width="19%">
        <el-radio-group v-model="productData.is_surprise">
          <el-radio :label="1" border>惊喜款</el-radio>
          <el-radio :label="2" border>超值款</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="产品原价" label-width="19%">
        <el-input v-model="productData.cost_price" />
      </el-form-item>
      <el-form-item label="中奖率" label-width="19%">
        <el-input-number v-model="productData.rate" />
      </el-form-item>
      <el-form-item label="惊喜款中奖所需次数" label-width="19%">
        <el-input-number v-model="productData.times" />
      </el-form-item>
      <el-form-item label="数量" label-width="19%">
        <el-input-number v-model="productData.number" />
      </el-form-item>
    </el-card>
    <div class="save-footer">
      <el-button type="primary" @click="saveChange()">保存</el-button>
      <el-button @click="goBack">取消</el-button>
    </div>
    <el-dialog v-model="dialogVisible">
      <img w-full :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </div>
</template>
<script>
import {
  changeBlindBoxProduct,
  getBlindBoxCategory,
  getBlindBoxProductProductData,
} from "@/axios/api";
import Tinymce from "@/components/tinymce.vue";
import { Plus } from "@element-plus/icons-vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  name: "editBlindBoxesProduct",
  components: {
    Tinymce,
    Plus,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const product_id = route.query.product_id;
    const goBack = () => {
      router.push("/blindBoxes/products");
    };
    const localeActiveName = ref("en");
    const productData = ref({
      box_goods_name: "",
      box_goods_name_cn: "",
      description: "",
      description_cn: "",
      thumb: "",
      thumbnail: "",
      brand_id: "",
      cost_price: 0,
      is_surprise: 1,
      rate: 0,
      times: 0,
      number: 0,
    });
    const categoryOption = ref([]);
    const fileList = ref([]);
    const thumbnailFile = ref([]);
    const tinymce = ref(null);
    const tinymce_cn = ref(null);

    getCategoryOption();
    async function getCategoryOption() {
      try {
        const data = await getBlindBoxCategory();
        let options = [];
        data.data.forEach((element) => {
          options.push({ value: element.id, label: element.brand_name });
          categoryOption.value = options;
        });
      } catch (error) {
        console.log(error);
      }
    }
    getProductData();
    async function getProductData() {
      let params = { product_id: product_id };
      const data = await getBlindBoxProductProductData({ params });
      productData.value = data.data;
    }

    //  图片上传，处理函数
    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const handlePictureCardPreview = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true;
    };
    function uploadThumbnail() {
      let thumbnailFd = new FormData();
      if (thumbnailFile.value.length > 0) {
        thumbnailFile.value.forEach((file) => {
          thumbnailFd.append("thumbs[]", file.raw);
        });
        axios
          .post(
            "https://shop-api.w-superbox.com/api/v1/w-superbox/admin/uploadimgs",
            thumbnailFd,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            productData.value.thumbnail = response.data.data[0];
            submitProductData();
          });
      } else {
        submitProductData();
      }
    }
    function uploadThumb() {
      if (fileList.value.length > 0) {
        let fd = new FormData();
        fileList.value.forEach((file) => {
          fd.append("thumbs[]", file.raw);
        });
        axios
          .post(
            "https://shop-api.w-superbox.com/api/v1/w-superbox/admin/uploadimgs",
            fd,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            productData.value.thumb = response.data.data[0];
            uploadThumbnail();
          });
      } else {
        uploadThumbnail();
      }
    }
    const UploadImage = () => {
      if (fileList.value.length > 0 || thumbnailFile.value.length > 0) {
        uploadThumb();
      } else {
        submitProductData();
      }
    };
    const delProductImage = (type) => {
      if (type === "thumb") {
        productData.value.thumb = "";
      } else {
        productData.value.thumbnail = "";
      }
    };

    async function submitProductData() {
      try {
        await changeBlindBoxProduct({
          product_id: productData.value.id,
          box_goods_name: productData.value.box_goods_name,
          box_goods_name_cn: productData.value.box_goods_name_cn,
          description: tinymce.value.value,
          description_cn: tinymce_cn.value.value,
          brand_id: productData.value.brand_id,
          thumb: productData.value.thumb,
          thumbnail: productData.value.thumbnail,
          cost_price: productData.value.cost_price,
          is_surprise: productData.value.is_surprise,
          rate: productData.value.rate,
          times: productData.value.times,
          number: productData.value.number,
        });
        ElMessage({ message: "修改成功", type: "success" });
        router.push({ path: "/blindBoxes/products" });
      } catch (error) {
        console.log(error);
      }
    }

    const uploadRef = ref();
    let saveChange = async () => {
      try {
        // uploadRef.value.submit();
        UploadImage();
      } catch (error) {
        console.log(error);
      }
    };

    return {
      localeActiveName,
      productData,
      categoryOption,
      fileList,
      thumbnailFile,
      dialogImageUrl,
      dialogVisible,
      uploadRef,
      tinymce_cn,
      tinymce,

      goBack,
      handlePictureCardPreview,
      saveChange,
      delProductImage,
    };
  },
};
</script>
<style lang="less" scoped>
img {
  width: 100%;
}
.save-footer {
  text-align: center;
  padding: 20px 0;
}

.image-list {
  display: flex;
  flex-direction: column;
  .el-image {
    width: 96px;
    margin-bottom: 10px;
  }
  .del-image {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
