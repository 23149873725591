<template>
  <div class="create-product">
    <el-page-header @back="goBack"></el-page-header>
    <el-card>
      <el-tabs v-model="activeName" class="add-product">
        <el-tab-pane label="基本信息" name="info">
          <el-tabs v-model="localeActiveName" class="add-product">
            <el-tab-pane label="English" name="en">
              <el-form-item label="商品名称" label-width="15%">
                <el-input v-model="productData.goods_name" />
              </el-form-item>
              <el-form-item label="商品详情" label-width="15%">
                <el-input v-model="productData.details" />
              </el-form-item>
              <el-form-item label="商品描述" label-width="15%">
                <tinymce
                  ref="tinymce"
                  :tinymceHtml="productData.description"
                ></tinymce>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane label="中文" name="zh-cn">
              <el-form-item label="商品名称" label-width="15%">
                <el-input v-model="productData.goods_name_cn" />
              </el-form-item>
              <el-form-item label="商品详情" label-width="15%">
                <el-input v-model="productData.details_cn" />
              </el-form-item>
              <el-form-item label="商品描述" label-width="15%">
                <tinymce
                  ref="tinymce_cn"
                  :tinymceHtml="productData.description_cn"
                ></tinymce>
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
          <el-form-item label="类目" label-width="15%">
            <el-select v-model="productData.category_id" placeholder="请选择">
              <el-option
                v-for="item in categoryOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="图片" label-width="15%">
            <div class="image-list">
              <div
                class="image"
                v-for="(item, index) in productData.thumb"
                :key="index"
              >
                <el-badge value="主图" :hidden="index !== 0" type="info">
                  <el-image :src="item" />
                </el-badge>
                <div class="del-image" @click="delGoodsImage(index)">移除</div>
              </div>
            </div>
            <div>
              <el-upload
                ref="uploadRef"
                v-model:file-list="fileList"
                list-type="picture-card"
                action="#"
                :on-preview="handlePictureCardPreview"
                :auto-upload="false"
              >
                <el-icon><Plus /></el-icon>
              </el-upload>
              <el-dialog v-model="dialogVisible">
                <img w-full :src="dialogImageUrl" alt="Preview Image" />
              </el-dialog>
            </div>
          </el-form-item>
          <el-form-item label="状态" label-width="15%">
            <el-radio-group v-model="productData.status">
              <el-radio :label="1" border>上架</el-radio>
              <el-radio :label="2" border>下架</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="数据" name="data">
          <el-form-item label="商品类型" label-width="15%">
            <el-radio-group v-model="productData.goods_type">
              <el-radio-button :label="1">单规格商品</el-radio-button>
              <el-radio-button :label="2">多规格商品</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div v-if="productData.goods_type === 1">
            <el-form-item label="SKU" label-width="15%">
              <el-input v-model="productData.sku" />
            </el-form-item>
            <el-form-item label="商品型号" label-width="15%">
              <el-input v-model="productData.model" />
            </el-form-item>
            <el-form-item label="销售价格" label-width="15%">
              <el-input v-model="productData.price">
                <template #prepend>
                  <el-button>￥ CNY</el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="成本价格" label-width="15%">
              <el-input v-model="productData.cost_price">
                <template #prepend>
                  <el-button>￥ CNY</el-button>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="库存数量" label-width="15%">
              <el-input v-model="productData.stock" />
            </el-form-item>
          </div>
          <div v-if="productData.goods_type === 2">
            <div
              class="spec-content"
              v-for="(attr, index) in specData"
              :key="index"
            >
              <div class="add-spec">
                <el-form label-width="80px" style="width: 400px">
                  <el-form-item label="规格名">
                    <el-input
                      v-model="attr.value"
                      placeholder="请输入英文规格名"
                      ><template #prepend>English</template></el-input
                    >
                    <el-input
                      v-model="attr.value_cn"
                      placeholder="请输入中文规格名"
                    >
                      <template #prepend>中文</template>
                    </el-input>
                  </el-form-item>
                  <el-form-item label="规格值">
                    <el-tag
                      v-for="(tag, index) in attr.detail"
                      :key="tag"
                      closable
                      :disable-transitions="false"
                      @close="handleClose(tag, index, attr)"
                    >
                      {{ tag }} ( {{ attr.detail_cn[index] }} )
                    </el-tag>
                    <div class="add-item" v-if="attr.inputVisible">
                      <el-input
                        class="input-new-tag"
                        v-model="attr.inputValue"
                        ref="saveTagInput"
                      >
                        <template #prepend>English</template>
                      </el-input>
                      <el-input v-model="attr.inputValue_cn">
                        <template #prepend>中文</template>
                      </el-input>
                      <el-button
                        @click="
                          handleInputConfirm(
                            attr.inputValue,
                            attr.inputValue_cn,
                            attr
                          )
                        "
                        >提交</el-button
                      >
                    </div>
                    <el-button
                      v-else
                      class="button-new-tag"
                      size="small"
                      @click="showInput(attr, index)"
                      >+ 添加</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
              <div>
                <el-link type="danger" @click="delPrivateSpec(index)"
                  >删除规格</el-link
                >
              </div>
            </div>
            <el-button type="primary" @click="addPrivateSpec"
              >添加规格</el-button
            >
            <el-table
              ref="multipleTable"
              :data="tableColumnList.tableBodyList"
              stripe
              tooltip-effect="dark"
              style="width: 100%; margin-top: 1%"
              border
            >
              <el-table-column
                v-for="item in tableColumnList.tableHeaderList"
                :key="item.prop"
                :label="item.propName"
                :property="item.prop"
                align="center"
              >
                <template v-slot="scope">
                  <span>{{ scope.row[scope.column.property] }}</span>
                </template>
              </el-table-column>
              <el-table-column label="SKU">
                <template v-slot="scope">
                  <el-input v-model.number="scope.row.sku"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="商品型号">
                <template v-slot="scope">
                  <el-input v-model.number="scope.row.model"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="销售价格">
                <template v-slot="scope">
                  <el-input
                    v-model.number="scope.row.price"
                    type="number"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="成本价格">
                <template v-slot="scope">
                  <el-input
                    v-model.number="scope.row.cost_price"
                    type="number"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="库存">
                <template v-slot="scope">
                  <el-input v-model.number="scope.row.stock"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="状态">
                <template #default="scope">
                  <el-switch
                    v-model="scope.row.status"
                    :active-value="1"
                    :inactive-value="2"
                    active-text="上架"
                    inactive-text="下架"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-divider />
          <el-form-item label="最低购买数量" label-width="15%">
            <el-input v-model="productData.buy_min_num" />
          </el-form-item>
          <!-- <el-form-item label="尺寸" label-width="15%">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-input v-model="productData.longth">
                  <template #prepend>长</template>
                </el-input>
              </el-col>
              <el-col :span="6">
                <el-input v-model="productData.width">
                  <template #prepend>宽</template>
                </el-input>
              </el-col>
              <el-col :span="6">
                <el-input v-model="productData.height">
                  <template #prepend>高</template>
                </el-input>
              </el-col>
              <el-col :span="6">
                <el-select
                  v-model="productData.unit"
                  class="m-2"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in unitOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="缺货显示状态" label-width="15%">
            <el-radio-group v-model="productData.out_stock_status">
              <el-radio-button :label="1">2-3天后到货</el-radio-button>
              <el-radio-button :label="2">无货</el-radio-button>
              <el-radio-button :label="3">有货</el-radio-button>
              <el-radio-button :label="4">预售</el-radio-button>
            </el-radio-group>
            <el-alert
              title="商品缺货时，显示的缺货状态"
              type="info"
              show-icon
              :closable="false"
            />
          </el-form-item>
          <el-form-item label="需要配送" label-width="15%">
            <el-switch
              v-model="productData.is_shipping"
              :active-value="1"
              :inactive-value="2"
            />
          </el-form-item>
          <el-form-item label="购买减少库存" label-width="15%">
            <el-switch
              v-model="productData.is_stock"
              :active-value="1"
              :inactive-value="2"
            />
          </el-form-item> -->
        </el-tab-pane>
        <el-tab-pane label="SEO" name="seo">
          <el-form-item label="SEO URL" label-width="15%">
            <el-input v-model="productData.seo">
              <template #prepend>
                <el-button>CN</el-button>
              </template>
            </el-input>
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane label="营销活动" name="activity">暂无</el-tab-pane>
      </el-tabs>
    </el-card>
    <div class="save-footer">
      <el-button type="primary" @click="saveChange()">保存</el-button>
      <el-button @click="goBack">取消</el-button>
    </div>
  </div>
</template>
<script>
import { Plus } from "@element-plus/icons-vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import { computed, nextTick, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getCategorylists, getGoodInfo, storeGood } from "../../axios/api";
import Tinymce from "../../components/tinymce.vue";

export default {
  name: "Edit",
  components: {
    Tinymce,
    Plus,
  },
  setup() {
    // const unitOptions = [
    //   {
    //     value: "cm",
    //     label: "厘米",
    //   },
    //   {
    //     value: "mm",
    //     label: "毫米",
    //   },
    //   {
    //     value: "in",
    //     label: "英寸",
    //   },
    // ];
    const route = useRoute();
    const product_id = route.query.product_id;
    const router = useRouter();
    const goBack = () => {
      router.back();
    };
    const productData = ref({});
    const categoryOption = ref([]);
    const activeName = "info";
    const localeActiveName = "en";
    const isChange = ref(false);
    const tinymce = ref(null);
    const tinymce_cn = ref(null);

    const specData = ref([
      //sku属性
      // {
      //   value: "", //规格名
      //   detail: [], //规格值数组
      //   inputVisible: false,
      //   inputValue: "",
      // },
    ]);
    const tableColumnList = ref({
      tableHeaderList: [],
      tableBodyList: [{}],
    });

    getProductData();
    getCategoryOption();

    async function getProductData() {
      let params = { id: product_id };
      const data = await getGoodInfo({ params });
      productData.value = data.data;
      if (data.data.variants.length > 0) {
        data.data.variants.forEach((spec) => {
          specData.value.push({
            value: spec.name,
            value_cn: spec.name_cn,
            detail: spec.item,
            detail_cn: spec.item_cn,
            inputVisible: false,
            inputValue: "",
          });
        });
      }
    }
    async function getCategoryOption() {
      try {
        let params = {
          limit: 100,
          page: 1,
        };
        const data = await getCategorylists({ params });
        let options = [];
        data.data.forEach((element) => {
          // if (element.children_id !== null) {
          //   let params = { id: parseInt(element.children_id) };
          //   const secondData = getSecondCategorylists({ params });
          // }
          options.push({ value: element.id, label: element.category_name });
          categoryOption.value = options;
        });
      } catch (error) {
        console.log(error);
      }
    }

    // 图片上传，处理函数
    const uploadRef = ref();
    const fileList = ref([]);
    const dialogImageUrl = ref("");
    const dialogVisible = ref(false);
    const handlePictureCardPreview = (uploadFile) => {
      dialogImageUrl.value = uploadFile.url;
      dialogVisible.value = true;
    };
    function delGoodsImage(index) {
      productData.value.thumb.splice(index, 1);
    }
    const UploadImage = () => {
      let fd = new FormData();
      if (fileList.value.length > 0) {
        fileList.value.forEach((file) => {
          fd.append("thumbs[]", file.raw);
        });
        axios
          .post(
            "https://shop-api.w-superbox.com/api/v1/w-superbox/admin/uploadimgs",
            fd,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(async (response) => {
            const imageData = productData.value.thumb.concat(
              response.data.data
            );
            productData.value.thumb = imageData;
            submitProductData();
          });
      } else {
        submitProductData();
      }
    };

    let saveChange = async () => {
      try {
        UploadImage();
        // uploadRef.value.submit();
      } catch (error) {
        console.log(error);
      }
    };

    async function submitProductData() {
      try {
        const goods_params_list =
          productData.value.goods_type === 1
            ? [
                {
                  sku: productData.value.sku,
                  model: productData.value.model,
                  price: productData.value.price,
                  cost_price: productData.value.cost_price,
                  stock: productData.value.stock,
                },
              ]
            : tableColumnList.value.tableBodyList.map((item) => {
                return {
                  // variant: Object.entries(item)
                  //   .filter(
                  //     ([key, value]) =>
                  //       key !== "sku" &&
                  //       key !== "model" &&
                  //       key !== "price" &&
                  //       key !== "cost_price" &&
                  //       key !== "stock" &&
                  //       key !== "thumb" &&
                  //       key !== "status"
                  //   )
                  //   .map(([key, value]) => ({ [key]: value })),
                  variant: item.variant,
                  variant_cn: item.variant_cn,
                  sku: item.sku,
                  price: item.price,
                  stock: item.stock,
                  model: item.model,
                  cost_price: item.cost_price,
                  status: item.status,
                };
              });
        const goods_attr_list = specData.value.map((item) => {
          return {
            name: item.value,
            item: item.detail,
            name_cn: item.value_cn,
            item_cn: item.detail_cn,
          };
        });
        const data = {
          id: productData.value.id,
          goods_name: productData.value.goods_name,
          goods_name_cn: productData.value.goods_name_cn,
          details: productData.value.details,
          details_cn: productData.value.details_cn,
          description: tinymce.value.value,
          description_cn: tinymce_cn.value.value,
          thumb: productData.value.thumb,
          category_id: productData.value.category_id,
          status: productData.value.status,
          goods_type: productData.value.goods_type,
          goods_attr_list: JSON.stringify(goods_attr_list),
          goods_params_list: JSON.stringify(goods_params_list),
          buy_min_num: productData.value.buy_min_num,
        };
        await storeGood(data);
        ElMessage({ message: "修改成功", type: "success" });
        router.push({ path: "/goods" });
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }

    /* 多规格属性设置 */
    let calculateAttribute = computed(() => {
      // 初始化
      let obj = {};
      specData.value.forEach((item) => {
        // 判断有没有输入规格名
        if (item.value) {
          //规格名:规格值     //'颜色':'尺寸'
          obj[item.value] = item.detail;
        }
      });
      return obj;
    });
    watch(
      () => calculateAttribute.value,
      (value, oldValue) => {
        if (productData.value.goods_type !== 2) {
          return;
        }
        const change =
          oldValue !== undefined && Object.keys(oldValue).length !== 0
            ? true
            : false;
        attribute(value, change);
      },
      {
        immediate: true,
        deep: true, // 深度监听
      }
    );
    let attribute = async (value, change) => {
      try {
        let tableObj = {
          tableBodyList: [],
          tableHeaderList: [],
        };
        // 表格内容
        if (change) {
          /* 中文数据 */
          let obj_cn = {};
          specData.value.forEach((item) => {
            if (item.value_cn) {
              obj_cn[item.value_cn] = item.detail_cn;
            }
          });
          let cloneNewVal_cn = JSON.parse(JSON.stringify(obj_cn));
          let attrName_cn = []; //规格名数组
          let attrValue_cn = []; //规格值数组
          for (let key in cloneNewVal_cn) {
            attrName_cn.push(key);
            attrValue_cn.push(cloneNewVal_cn[key]);
          }
          // 表格内容数据（笛卡尔积算法）
          let finalArr_cn = cartesianProductOf(...attrValue_cn);

          let cloneNewVal = JSON.parse(JSON.stringify(value));
          let attrName = []; //规格名数组
          let attrValue = []; //规格值数组
          for (let key in cloneNewVal) {
            attrName.push(key);
            attrValue.push(cloneNewVal[key]);
          }
          // 表格内容数据（笛卡尔积算法）
          let finalArr = cartesianProductOf(...attrValue);
          // 表格内容
          tableObj.tableBodyList = finalArr.map((item, index) => {
            let obj = {
              sku: "", //sku
              model: "", //商品型号
              price: 0, //价格
              cost_price: 0, //成本价格
              stock: 0, //库存
              status: 1, //状态
              thumb: "", //图片
              variant: [],
              variant_cn: [],
            };
            for (let i = 0; i < item.length; i++) {
              obj[attrName[i]] = item[i];
              let param_cn = {};
              param_cn[attrName_cn[i]] = finalArr_cn[index][i];
              obj.variant_cn.push(param_cn);
              let param = {};
              param[attrName[i]] = item[i];
              obj.variant.push(param);
            }
            return obj;
          });
          tableColumnList.value.tableBodyList = tableObj.tableBodyList; //表格内容数据
        } else {
          const data = productData.value.goods_params_list.map((item) => {
            const newItem = {};
            item.variant.forEach((variant) => {
              const key = Object.keys(variant)[0];
              const value = variant[key];
              newItem[key] = value;
            });
            newItem.sku = item.sku;
            newItem.price = item.price;
            newItem.cost_price = item.cost_price;
            newItem.stock = item.stock;
            newItem.model = item.model;
            newItem.status = item.status;
            newItem.thumb = item.thumb;
            newItem.variant = item.variant;
            newItem.variant_cn = item.variant_cn;
            return newItem;
          });
          tableColumnList.value.tableBodyList = data;
        }

        // 表头
        let skuTableArr = Object.keys(value);
        tableObj.tableHeaderList = skuTableArr.map((item) => {
          return {
            prop: item,
            propName: item,
          };
        });
        tableColumnList.value.tableHeaderList = tableObj.tableHeaderList; // 表头
      } catch (error) {
        console.log(error);
      }
    };
    let cartesianProductOf = (...args) => {
      return args.reduce(
        (total, current) => {
          let ret = [];
          total.forEach((a) => {
            current.forEach((b) => {
              ret.push(a.concat([b]));
            });
          });
          return ret;
        },
        [[]]
      );
    };
    let addPrivateSpec = () => {
      specData.value.push({
        value: "",
        value_cn: "",
        detail: [],
        detail_cn: [],
        inputVisible: false,
        inputValue: "",
        inputValue_cn: "",
      });
    };
    let handleClose = (tag, index, item) => {
      item.detail.splice(item.detail.indexOf(tag), 1);
      item.detail_cn.splice(index, 1);
    };
    let handleInputConfirm = (val, val_cn, attr) => {
      if (val) {
        attr.detail.push(val);
        attr.detail_cn.push(val_cn ?? "");
      }
      attr.inputVisible = false;
      attr.inputValue = "";
      attr.inputValue_cn = "";
    };
    const saveTagInput = ref(null);
    let showInput = (attr, index) => {
      attr.inputVisible = true;
      nextTick(() => {
        if (saveTagInput.value) {
          saveTagInput.value[0].focus();
        }
      });
    };
    let delPrivateSpec = (index) => {
      specData.value.splice(index, 1);
    };
    return {
      activeName,
      localeActiveName,
      goBack,
      productData,
      categoryOption,
      isChange,
      // unitOptions,

      delGoodsImage,
      fileList,
      dialogImageUrl,
      dialogVisible,
      handlePictureCardPreview,
      uploadRef,
      UploadImage,

      saveChange,
      tinymce_cn,
      tinymce,

      specData,
      tableColumnList,
      addPrivateSpec,
      handleClose,
      handleInputConfirm,
      showInput,
      saveTagInput,
      delPrivateSpec,
    };
  },
};
</script>
<style scoped lang="less">
img {
  width: 100%;
}
.create-product {
  padding-bottom: 2rem;
}
.tinymce-container {
  width: 100%;
}
.save-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: #fff;
  padding: 20px 0;
  border-top: 1px solid #eee;
  z-index: 10;
}
.image-list {
  margin: 1rem;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  .image {
    width: 96px;
    margin-right: 40px;
    margin-bottom: 10px;
    object-fit: cover;
    .del-image {
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.spec-content {
  padding: 15px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  background-color: #fcfcfc;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  .add-spec {
    flex: 1;
    .el-tag {
      margin-right: 0.5rem;
    }

    .add-item {
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>
<style>
.add-product > .el-tabs__content {
  padding: 20px 50px;
  color: #6b778c;
}

.el-form-item__label {
  font-size: 14px;
}
</style>
