<template>
  <div>
    <!-- 返回上一页面 -->
    <el-page-header @back="goBack"></el-page-header>
    <!-- 订单详情 -->
    <el-card>
      <!-- 订单详情 -->
      <el-descriptions title="订单详情" :column="2" border>
        <template #extra>
          <el-button type="primary">打印发货单</el-button>
          <el-button type="primary">打印发票</el-button>
        </template>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">订单ID</div>
          </template>
          {{ orderdetail.id }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">生成日期</div>
          </template>
          {{ orderdetail.created_at }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">支付方式</div>
          </template>
          {{ pay_method }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">配送方式</div>
          </template>
          {{ shipping_method }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 客户明细 -->
      <el-descriptions class="margin-top" title="客户明细" :column="2" border>
        <template #extra>
          <el-button type="primary" @click="openUserInfoDialog()"
            >编辑客户信息</el-button
          >
        </template>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">客户名称</div>
          </template>
          {{ buyerdetail.name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">客户组</div>
          </template>
          {{ buyerdetail.user_group }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">客户邮箱</div>
          </template>
          {{ buyerdetail.email }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template #label>
            <div class="cell-item">客户电话</div>
          </template>
          {{ buyerdetail.phone }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 订单状态 -->
      <el-descriptions class="margin-top" title="订单状态" :column="1">
        <el-descriptions-item label="当前状态">
          <el-tag v-if="orderdetail.order_status === 1" type="warning"
            >待支付</el-tag
          >
          <el-tag v-if="orderdetail.order_status === 2" type="info"
            >待处理</el-tag
          >
          <el-tag v-if="orderdetail.order_status === 3" type="info"
            >已审核</el-tag
          >
          <el-tag v-if="orderdetail.order_status === 4" type="info"
            >已取消</el-tag
          >
          <el-tag v-if="orderdetail.order_status === 5" type="success"
            >已支付</el-tag
          >
          <el-tag v-if="orderdetail.order_status === 6" type="danger"
            >退款中</el-tag
          >
          <el-tag v-if="orderdetail.order_status === 7" type="info"
            >支付失败</el-tag
          >
          <el-tag v-if="orderdetail.order_status === 8" type="primary"
            >部分发货</el-tag
          >
          <el-tag v-if="orderdetail.order_status === 9" type="primary"
            >全部发货</el-tag
          >
          <el-tag v-if="orderdetail.order_status === 10" type="success"
            >已完成</el-tag
          >
          <div
            style="margin-top: 1rem"
            v-if="
              orderdetail.order_status !== 10 && orderdetail.order_status !== 4
            "
          >
            <el-button
              v-for="item in orderStatusBtns"
              :key="item"
              @click="changeOrderStatus(item)"
            >
              {{ item.name }}</el-button
            >
          </div>
          <div
            style="margin-top: 1rem"
            v-if="
              orderdetail.order_status === 10 || orderdetail.order_status === 4
            "
          >
            订单流程已结束，状态不可更改
          </div>
        </el-descriptions-item>
      </el-descriptions>
      <!-- 配送信息 -->
      <el-descriptions class="margin-top" title="配送信息" :column="1" border>
        <template #extra>
          <el-button type="primary" @click="openAddressInfoDialog()"
            >编辑配送地址</el-button
          >
        </template>
        <el-descriptions-item label="收件人">{{
          addressdetail.first_name + " " + addressdetail.last_name
        }}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{
          addressdetail.phone_num
        }}</el-descriptions-item>
        <el-descriptions-item label="地址">{{
          addressdetail.address +
          ", " +
          addressdetail.city +
          (addressdetail.postcode ? ", " + addressdetail.postcode : "") +
          (addressdetail.state_province
            ? ", " + addressdetail.state_province
            : "") +
          (addressdetail.country ? ", " + addressdetail.country : "")
        }}</el-descriptions-item>
      </el-descriptions>
      <!-- 订单商品 -->
      <div class="margin-top">
        <h4>订单商品</h4>
        <el-table :data="goodsdetail" style="width: 100%; margin-top: 1rem">
          <el-table-column prop="thumb" label="图片">
            <template #default="scope">
              <div style="display: inline-flex" v-if="scope.row.thumb">
                <el-image class="goods_image" :src="scope.row.thumb" />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="goods_name" label="商品名称" width="250" />
          <el-table-column prop="model" label="型号" />
          <el-table-column prop="sku" label="SKU" />
          <el-table-column prop="goods_num" label="数量" />
          <el-table-column prop="cost_price" label="成本价" />
          <el-table-column prop="price" label="价格" width="150" />
          <el-table-column prop="total_price" label="总计" width="150" />
        </el-table>
        <div class="total-content">
          运费<span class="price">{{ 0.0 }}</span>
        </div>
        <div class="total-content">
          总价<span class="price">{{ orderdetail.order_price }}</span>
        </div>
      </div>
    </el-card>
    <!-- 编辑客户数据弹窗 -->
    <el-dialog v-model="userInfoDialog" title="修改客户明细" width="50%">
      <el-form :model="userInfoForm" label-width="100px">
        <el-form-item label="客户名称">
          <el-input v-model="userInfoForm.name" />
        </el-form-item>
        <el-form-item label="客户组">
          <el-input v-model="userInfoForm.user_group" />
        </el-form-item>
        <el-form-item label="客户邮箱">
          <el-input v-model="userInfoForm.email" />
        </el-form-item>
        <el-form-item label="客户电话">
          <el-input v-model="userInfoForm.phone" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="userInfoDialog = false">取消</el-button>
          <el-button type="primary" @click="updateUserInfo()">修改</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑收货地址 -->
    <el-dialog v-model="addressInfoDialog" title="修改配送信息" width="50%">
      <!-- <el-form :model="addressInfoForm" label-width="100px">
        <el-form-item label="收件人">
          <el-input v-model="addressInfoForm.true_name" />
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="addressInfoForm.phone_num" />
        </el-form-item>
        <el-form-item label="地址">
          <el-input v-model="addressInfoForm.address_detail" />
        </el-form-item>
      </el-form> -->
      <el-form :model="addressInfoForm" label-width="80px">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="姓名">
              <el-input v-model="addressInfoForm.first_name" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="姓氏">
              <el-input v-model="addressInfoForm.last_name" /> </el-form-item
          ></el-col>
        </el-row>
        <el-form-item label="国家/地区">
          <el-select
            v-model="addressInfoForm.country"
            class="m-2"
            placeholder="Select"
            @change="changeCountry()"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.country"
              :value="item.country"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="州/省">
          <el-select
            v-model="addressInfoForm.state_province"
            class="m-2"
            placeholder="Select"
          >
            <el-option
              v-for="(item, index) in stateOptions"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="城市">
          <el-input v-model="addressInfoForm.city" />
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input v-model="addressInfoForm.address" />
        </el-form-item>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="邮编">
              <el-input v-model="addressInfoForm.postcode" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电话">
              <el-input v-model="addressInfoForm.phone_num" /> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addressInfoDialog = false">取消</el-button>
          <el-button type="primary" @click="updateAddressInfo()"
            >修改</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from "element-plus";
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import options from "../../assets/js/addressJson";
import {
  changeOrderStatus,
  getOrderDetail,
  storeAddressDetail,
  storeBuyerDetail,
} from "../../axios/api";

export default {
  name: "OrderDetails",
  components: {},
  setup() {
    const userInfoDialog = ref(false);
    const addressInfoDialog = ref(false);
    const router = useRouter();
    const goBack = () => {
      router.back();
    };
    const route = useRoute();
    const order_id = route.query.order_id;
    let orderdetail = ref({});
    let goodsdetail = ref([]);
    let addressdetail = ref({});
    let buyerdetail = ref({});
    let pay_method = ref("");
    let shipping_method = ref("");
    let orderStatusBtns = ref([]);

    getOrderInfo(order_id);
    async function getOrderInfo(order_id) {
      try {
        const params = { id: order_id };
        const data = await getOrderDetail({ params });
        orderdetail.value = data.data.orderdetail[0];
        goodsdetail.value = data.data.goodsdetail;
        addressdetail.value = data.data.addressdetail[0];
        buyerdetail.value = data.data.buyerdetail[0];
        updateShow();
      } catch (error) {
        console.log(error);
      }
    }
    function updateShow() {
      if (orderdetail.value.pay_type === 1) {
        pay_method.value = "支付宝";
      } else if (orderdetail.value.pay_type === 2) {
        pay_method.value = "微信支付";
      } else if (orderdetail.value.pay_type === 3) {
        pay_method.value = "信用卡";
      } else {
        pay_method.value = "货到付款";
      }
      if (orderdetail.value.shipping_type === 1) {
        shipping_method.value = "免费配送";
      } else if (orderdetail.value.shipping_type === 2) {
        shipping_method.value = "按件运费";
      } else if (orderdetail.value.shipping_type === 3) {
        shipping_method.value = "到店自提";
      } else {
        shipping_method.value = "固定运费";
      }
      if (orderdetail.value.order_status === 1) {
        orderStatusBtns.value = [
          { name: "已支付", value: 5 },
          { name: "已取消", value: 4 },
          { name: "支付失败", value: 7 },
        ];
      } else if (orderdetail.value.order_status === 2) {
        orderStatusBtns.value = [
          { name: "已审核", value: 3 },
          { name: "已取消", value: 4 },
        ];
      } else if (orderdetail.value.order_status === 3) {
        orderStatusBtns.value = [
          { name: "部分发货", value: 8 },
          { name: "全部发货", value: 9 },
          { name: "已取消", value: 4 },
        ];
      } else if (orderdetail.value.order_status === 5) {
        orderStatusBtns.value = [
          { name: "部分发货", value: 8 },
          { name: "全部发货", value: 9 },
          { name: "已取消", value: 4 },
          { name: "已完成", value: 10 },
        ];
      }
    }

    return {
      goBack,
      userInfoDialog,
      addressInfoDialog,
      orderdetail,
      goodsdetail,
      addressdetail,
      buyerdetail,
      pay_method,
      shipping_method,
      orderStatusBtns,
      options,
    };
  },
  data() {
    return {
      userInfoForm: {},
      addressInfoForm: {},
      stateOptions: [],
    };
  },
  mounted() {},
  methods: {
    /* 修改订单状态 */
    changeOrderStatus(order_status) {
      ElMessageBox.confirm("确认将订单状态修改为 " + order_status.name + "?")
        .then(async () => {
          try {
            await changeOrderStatus({
              id: this.orderdetail.id,
              order_status: order_status.value,
            });
          } catch (error) {
            console.log(error);
          }
          ElMessage({ message: "修改成功", type: "success" });
          this.orderdetail.order_status = order_status.value;
        })
        .catch(() => {
          // catch error
        });
    },
    /* 打开编辑用户明细弹窗 */
    openUserInfoDialog() {
      this.userInfoDialog = true;
      this.userInfoForm = this.buyerdetail;
    },
    /* 修改用户信息 */
    async updateUserInfo() {
      try {
        this.userInfoForm;
        await storeBuyerDetail({
          userid: this.userInfoForm.id,
          name: this.userInfoForm.name,
          user_group: this.userInfoForm.user_group,
          email: this.userInfoForm.email,
          phone: this.userInfoForm.phone,
        });
        ElMessage({ message: "修改成功", type: "success" });
      } catch (error) {
        console.log(error);
      }
      this.userInfoDialog = false;
      console.log(this.userInfoForm);
    },
    /* 打开编辑配送地址弹窗 */
    openAddressInfoDialog() {
      this.addressInfoDialog = true;
      this.addressInfoForm = this.addressdetail;
      options.forEach((arr) => {
        if (arr.country === this.addressInfoForm.country) {
          this.stateOptions = arr.zones;
        }
      });
    },
    /* 修改配送地址 */
    async updateAddressInfo() {
      try {
        await storeAddressDetail({
          addr_id: this.addressInfoForm.id,
          first_name: this.addressInfoForm.first_name,
          last_name: this.addressInfoForm.last_name,
          phone_num: this.addressInfoForm.phone_num,
          country: this.addressInfoForm.country,
          address: this.addressInfoForm.address,
          state_province: this.addressInfoForm.state_province,
          city: this.addressInfoForm.city,
          postcode: this.addressInfoForm.postcode,
        });
        ElMessage({ message: "修改成功", type: "success" });
      } catch (error) {
        console.log(error);
      }
      this.addressInfoDialog = false;
    },

    changeCountry() {
      this.addressInfoForm.state_province = "";
      options.forEach((item) => {
        if (item.country === this.addressInfoForm.country) {
          this.stateOptions = item.zones;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.margin-top {
  margin-top: 2rem;
}

.goods_image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.total-content {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  padding: 1.5rem 0;

  border-bottom: 1px solid #ebeef5;
  .price {
    width: 140px;
    padding-left: 2rem;
  }
}
.total-content:last-child {
  border-bottom: unset;
}
</style>
