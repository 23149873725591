import AdminList from "@/view/admin/AdminList";
import addBlindBoxesProduct from "@/view/blindBoxes/addBlindBoxesProduct";
import blindBoxesCategory from "@/view/blindBoxes/blindBoxesCategory";
import blindBoxesOrderList from "@/view/blindBoxes/blindBoxesOrderList";
import blindBoxesProducts from "@/view/blindBoxes/blindBoxesProducts";
import blindBoxesRechargeList from "@/view/blindBoxes/blindBoxesRechargeList";
import editBlindBoxesProduct from "@/view/blindBoxes/editBlindBoxesProduct";
import CustomerList from "@/view/customer/CustomerList";
import Home from "@/view/dashboard/Home";
import Index from "@/view/dashboard/Index";
import Order from "@/view/order/Order";
import OrderDetails from "@/view/order/OrderDetails";
import Ship from "@/view/order/Ship";
import Category from "@/view/product/Category";
import Add from "@/view/product/CreateProduct";
import Edit from "@/view/product/EditProduct";
import Goods from "@/view/product/Goods";
import ShippingList from "@/view/shipping/ShippingList";
import Error from "@/view/sys/Error";
import Login from "@/view/sys/Login";
import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/',
        component: Home,
        redirect: '/index',
        children: [
            {
                path: '/index',
                name: 'index',
                component: Index,
            },
        ],
    },
    {
        path: '/product',
        name: 'Product',
        component: Home,
        redirect: '/goods',
        children: [
            {
                path: '/goods',
                name: 'goods',
                component: Goods,
            },
            {
                path: 'add',
                name: 'Add',
                component: Add,
            },
            {
                path: '/category',
                name: 'category',
                component: Category,
            },
            {
                path: 'edit',
                name: 'Edit',
                component: Edit,
            },
        ]
    },
    {
        path: '/order',
        name: 'Order',
        component: Home,
        redirect: '/order',
        children: [
            {
                path: '/order',
                name: 'order',
                component: Order,
            },
            {
                path: 'details',
                name: 'details',
                component: OrderDetails,
            },
            {
                path: '/ship',
                name: 'Ship',
                component: Ship,
            }
        ]
    },
    {
        path: '/adminList',
        name: 'Admin',
        component: Home,
        redirect: '/adminList',
        children: [
            {
                path: '/adminList',
                name: 'AdminList',
                component: AdminList,
            },
            // {
            //     path: '/permission',
            //     name: 'Permission',
            //     component: Permission,
            // },
        ]
    },
    {
        path: '/shippingList',
        name: 'Shipping',
        component: Home,
        redirect: 'shipping',
        children: [
            {
                path: '/shippingList',
                name: 'shippingList',
                component: ShippingList
            }
        ]
    },
    {
        path: '/customerList',
        name: 'Customer',
        component: Home,
        redirect: 'customer',
        children: [
            {
                path: '/customerList',
                name: 'CustomerList',
                component: CustomerList
            }
        ]
    },
    {
        path: '/blindBoxes',
        name: 'blindBoxes',
        component: Home,
        redirect: '/products',
        children: [
            {
                path: '/blindBoxes/products',
                name: 'blindBoxesProducts',
                component: blindBoxesProducts,
            },
            {
                path: '/blindBoxes/category',
                name: 'blindBoxesCategory',
                component: blindBoxesCategory,
            },
            {
                path: '/blindBoxes/products/add',
                name: 'addBlindBoxesProduct',
                component: addBlindBoxesProduct,
            },
            {
                path: '/blindBoxes/products/edit',
                name: 'editBlindBoxesProduct',
                component: editBlindBoxesProduct,
            },
            {
                path: '/blindBoxes/rechargeList',
                name: 'blindBoxesRechargeList',
                component: blindBoxesRechargeList,
            },
            {
                path: '/blindBoxes/orderList',
                name: 'blindBoxesOrderList',
                component: blindBoxesOrderList,
            },
        ]
    },
    {
        path: '/404',
        component: Home,
        redirect: '/404',
        children: [
            {
                path: '/404',
                name: 'Error',
                component: Error
            }
        ]
    },
]

const router = createRouter({
    history: createWebHashHistory(), routes
})

router.beforeEach(async (to, from, next) => {
    if (to.matched.length === 0) {
        from.name ? next({ name: from.name }) : next('/404')
    } else {
        const token = localStorage.getItem('token')
        if (token === undefined && to.path !== '/login') {
            router.push({ path: '/login' })
        }
        if ((to.path === '/' || to.path === '/login') && token) {
            router.push({ path: '/index' })
        }
        next();
    }
})

export default router;