import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {

            // 默认激活
            defaultActive: '/index',

            // 动态导航
            navigation: [{
                name: '我的主页',
                index: '/index',
            }],

            // 结果页标题状态
            pageTitle: '',

            tabList: [
                {
                    name: '我的主页',
                    index: '/index',
                },
                {
                    name: '商品管理',
                    index: '/goods',
                },
                {
                    name: '商品分类',
                    index: '/category',
                },
                {
                    name: '订单管理',
                    index: '/order',
                },
                {
                    name: '发货管理',
                    index: '/ship',
                },
                {
                    name: '管理列表',
                    index: '/adminList',
                },
                {
                    name: '权限设置',
                    index: '/permission',
                },
                {
                    name: '盲盒分类',
                    index: '/blindBoxes/category',
                },
                {
                    name: '盲盒产品',
                    index: '/blindBoxes/products',
                },
                {
                    name: '充值记录',
                    index: '/blindBoxes/rechargeList',
                },
                {
                    name: '盲盒订单',
                    index: '/blindBoxes/orderList',
                },
                {
                    name: '客户管理',
                    index: '/customerList',
                },
                {
                    name: '运费管理',
                    index: '/shippingList',
                },
            ]
        }
    },
    mutations: {
        setMenu(state, index) {
            state.defaultActive = index
        },
        getNav(state, index) {
            state.tabList.forEach((menu) => {
                if (menu.index === index) {
                    state.navigation.push(menu);
                }
            })
        },
        addNav(state, item) {
            let isExist = false;
            state.navigation.forEach((menu) => {
                if (menu.index === item[0].index) {
                    isExist = true;
                }
            })
            if (!isExist) {
                state.navigation.push(item[0]);
            }
        },
        setPageTitle(state, title) {
            state.pageTitle = title
        }
    },
    actions: {},
    modules: {}
})

export default store;