<template>
  <div class="tinymce-container">
    <editor v-model="value" :init="init"></editor>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import tinymce from "tinymce";
import "tinymce/icons/default";
import "tinymce/plugins/code"; // 源代码
import "tinymce/plugins/image"; // 插入上传图片插件
import "tinymce/plugins/lists"; // 列表插件
import "tinymce/plugins/media"; // 插入视频插件
import "tinymce/plugins/table"; // 插入表格插件
import "tinymce/plugins/wordcount"; // 字数统计插件
import "tinymce/themes/silver";

import "tinymce/skins/ui/oxide/skin.css";

export default {
  name: "MyTinymce",
  props: {
    tinymceHtml: {
      type: String,
      default: "",
    },
    tinymceHeight: {
      type: Number,
      default: 500,
    },
  },
  components: {
    Editor,
  },
  data() {
    return {
      value: this.tinymceHtml, //父组件通过ref拿到该组件的值
      init: {
        selector: "#tinymce",
        skin_url: "/tinymce/skins/ui/oxide", //编辑器需要一个skin才能正常工作，所以要设置一个skin_url指向之前复制出来的skin文件
        height: this.tinymceHeight,
        plugins: "code lists image media table wordcount",
        // toolbar: 'undo redo |  formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat',
        toolbar:
          "code undo redo restoredraft | bold italic underline align | forecolor backcolor fullscreen | formatselect | outdent indent ltr rtl | bullist numlist | removeformat | \table image media charmap emoticons hr pagebreak insertdatetime print preview link table | searchreplace",
        toolbar_groups: {
          formatting: {
            icon: "bold",
            tooltip: "formatting",
            items: "alignleft | aligncenter | alignright",
          },
        },
        browser_spellcheck: true, // 拼写检查
        branding: false, // 去水印
        paste_data_images: true, // 允许粘贴图像
        menubar: false, // 隐藏最上方menu
        images_upload_handler: (blobInfo) => {
          let fd = new FormData();
          fd.append("file", blobInfo.blob());
        },
        content_style: "img {max-width:100%;}",
      },
    };
  },
  watch: {
    tinymceHtml(newV) {
      this.value = newV;
      this.$emit("input", newV);
    },
  },
  mounted() {
    tinymce.init({});
  },
};
</script>
<style lang="scss" scoped></style>
