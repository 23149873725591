<template>
  <div>
    <!-- 查询 -->
    <el-card>
      <el-form
        :inline="true"
        :model="query"
        label-width="80px"
        ref="query"
        class="query_form"
      >
        <el-col :span="24">
          <el-form-item label="订单ID" prop="id">
            <el-input v-model.number="query.id" placeholder="订单ID" />
          </el-form-item>
          <el-form-item label="订单号" prop="order_num">
            <el-input v-model.number="query.order_num" placeholder="订单号" />
          </el-form-item>
          <el-form-item label="客户" prop="buyer_name">
            <el-input v-model.number="query.buyer_name" placeholder="客户" />
          </el-form-item>
          <el-form-item label="订单状态" prop="order_status">
            <el-select
              v-model="query.order_status"
              class="m-2"
              placeholder="请选择"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="总计" prop="order_price">
            <el-input
              v-model.number="query.order_price"
              placeholder="(格式: 10 或 10-20)"
            />
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="生成日期">
            <el-col :span="11">
              <el-form-item prop="created_at_start" style="margin-right: unset">
                <el-date-picker
                  v-model="query.created_at_start"
                  type="date"
                  placeholder="订单生成日期（开始）"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
            <el-col class="text-center" :span="2">
              <span class="text-gray-500">-</span>
            </el-col>
            <el-col :span="11">
              <el-form-item prop="created_at_end">
                <el-date-picker
                  v-model="query.created_at_end"
                  type="date"
                  placeholder="订单生成日期（结束）"
                  style="width: 100%"
                />
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-col>
        <el-form-item label=" ">
          <el-button type="primary" :icon="Search" @click="getShipList"
            >查询</el-button
          >
          <el-button type="primary" :icon="Brush" @click="resetForm" />
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 发货列表 -->
    <el-card>
      <el-button type="primary">快递信息导入</el-button>
      <el-table
        :data="shipList"
        :height="tableHeight"
        style="width: 100%; margin-top: 1rem"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" sortable width="70" />
        <el-table-column prop="order_num" label="订单号" sortable width="200" />
        <el-table-column prop="buyer_name" label="客户" width="150" />
        <el-table-column prop="order_status" label="订单状态" width="150">
          <template #default="scope">
            <el-tag v-if="scope.row.order_status === 1" type="warning"
              >待支付</el-tag
            >
            <el-tag v-if="scope.row.order_status === 2" type="info"
              >待处理</el-tag
            >
            <el-tag v-if="scope.row.order_status === 3" type="info"
              >已审核</el-tag
            >
            <el-tag v-if="scope.row.order_status === 4" type="info"
              >已取消</el-tag
            >
            <el-tag v-if="scope.row.order_status === 5" type="success"
              >已支付</el-tag
            >
            <el-tag v-if="scope.row.order_status === 6" type="danger"
              >退款中</el-tag
            >
            <el-tag v-if="scope.row.order_status === 7" type="info"
              >支付失败</el-tag
            >
            <el-tag v-if="scope.row.order_status === 8" type="primary"
              >部分发货</el-tag
            >
            <el-tag v-if="scope.row.order_status === 9" type="primary"
              >全部发货</el-tag
            >
            <el-tag v-if="scope.row.status === 10" type="success"
              >已完成</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="order_price" label="订单总额" width="150" />
        <el-table-column prop="created_at" label="添加时间" width="200" />
        <el-table-column prop="updated_at" label="编辑时间" width="200" />
        <el-table-column fixed="right" label="操作" width="215">
          <template #default="scope">
            <el-button
              type="primary"
              @click="shipMangage(scope.$index, scope.row)"
              >发货管理</el-button
            >
            <el-button
              type="primary"
              @click="shipDetails(scope.$index, scope.row)"
              >订单详情</el-button
            >
          </template>
        </el-table-column>
        <template #empty>
          <div style="margin: 50px 0">
            <el-empty v-if="showEmpty" description="暂时还没有订单哦" />
          </div>
        </template>
      </el-table>
      <div style="padding-top: 15px">
        <el-pagination
          layout="total, prev, pager, next"
          :current-page="pageNum"
          :page-size="pageSize"
          :total="total"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChangePrev"
          @next-click="handleCurrentChangeNext"
          background
        />
      </div>
    </el-card>
    <!-- 物流管理弹窗 -->
    <el-dialog
      v-model="shipDialogFormVisible"
      width="80%"
      :title="'订单发货管理(' + editOrderID + '#' + editOrderNum + ')'"
    >
      <div class="dialog-content">
        <el-alert
          :title="'收货地址：' + editAddress"
          type="success"
          :closable="false"
        />
        <el-form :model="shipForm" label-width="100px">
          <el-form-item label="通知客户">
            <el-switch
              v-model="shipForm.is_notice"
              :active-value="1"
              :inactive-value="2"
            />
          </el-form-item>
          <el-form-item label="快递平台">
            <el-radio-group v-model="shipForm.express_platform">
              <el-radio-button :label="1">快递鸟</el-radio-button>
              <el-radio-button :label="2">快递100</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="快递公司">
            <el-radio-group v-model="shipForm.express_company">
              <el-radio-button :label="1">申通</el-radio-button>
              <el-radio-button :label="2">EMS</el-radio-button>
              <el-radio-button :label="3">顺丰速递</el-radio-button>
              <el-radio-button :label="4">圆通速递</el-radio-button>
              <el-radio-button :label="5">韵达快递</el-radio-button>
              <el-radio-button :label="6">百世汇通</el-radio-button>
              <el-radio-button :label="7">德邦物流</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="单号类型">
            <el-radio-group v-model="shipForm.odd_type">
              <el-radio-button :label="1">手动输入</el-radio-button>
              <el-radio-button :label="2">电子面单</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="快递单号">
            <el-input v-model="shipForm.express_num" autocomplete="off" />
          </el-form-item>
          <el-form-item label="说明">
            <el-input v-model="shipForm.remarks" type="textarea" />
          </el-form-item>
          <div style="text-align: right">
            <el-button
              type="primary"
              :icon="CirclePlusFilled"
              :disabled="isShip"
              @click="addShip"
              >{{ allShip }}</el-button
            >
          </div>
        </el-form>
        <el-tabs
          v-model="activeName"
          class="demo-tabs"
          @tab-click="handleClick"
        >
          <el-tab-pane label="订单商品" name="product">
            <el-table :data="orderData" style="width: 100%">
              <el-table-column prop="thumb" label="商品图片">
                <template #default="scope">
                  <div style="display: inline-flex">
                    <el-image class="image" :src="scope.row.thumb" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="goods_name" label="商品名称" width="180" />
              <el-table-column prop="model" label="型号" />
              <el-table-column prop="goods_num" label="数量" />
              <el-table-column prop="send_num" label="已发货数量" width="100">
                <template #default="scope">
                  <el-input-number
                    v-model="scope.row.send_num"
                    :min="comparativeData[scope.$index].send_num"
                    :max="scope.row.goods_num"
                    controls-position="right"
                    @change="changeShipmentsNumber(scope.$index, scope.row)"
                  />
                </template>
              </el-table-column>
              <el-table-column prop="cost_price" label="成本价" />
              <el-table-column prop="price" label="价格" />
              <el-table-column label="总计">
                <template #default="scope"
                  >{{
                    parseFloat(scope.row.goods_num) *
                    parseFloat(scope.row.price)
                  }}
                </template></el-table-column
              >
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="物流信息" name="ship">
            <el-table :data="shipData" style="width: 100%">
              <el-table-column prop="created_at" label="添加时间" width="150" />
              <el-table-column
                prop="express_company"
                label="快递公司"
                width="130"
              >
                <template #default="scope">
                  <div v-if="scope.row.express_company === 1">申通快递</div>
                  <div v-if="scope.row.express_company === 2">EMS</div>
                  <div v-if="scope.row.express_company === 3">顺丰快递</div>
                  <div v-if="scope.row.express_company === 4">圆通快递</div>
                  <div v-if="scope.row.express_company === 5">韵达快递</div>
                  <div v-if="scope.row.express_company === 6">百世汇通</div>
                  <div v-if="scope.row.express_company === 7">德邦物流</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="express_num"
                label="快递单号"
                width="200"
              />
              <el-table-column prop="remarks" label="附言" />
              <!-- <el-table-column prop="ship_info" label="物流详情" width="250" /> -->
              <el-table-column fixed="right" label="操作" min-width="100">
                <template #default="scope">
                  <el-popconfirm
                    title="确定要删除这条物流信息吗?"
                    confirmButtonText="确认"
                    cancelButtonText="取消"
                    cancelButtonType="default"
                    :icon="WarningFilled"
                    @confirm="deleteShip(scope.row)"
                  >
                    <template #reference>
                      <el-button type="danger">删除</el-button>
                    </template>
                  </el-popconfirm>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Brush, CirclePlusFilled, Search } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { cloneDeep } from "lodash-es";
import { ref } from "vue";
import {
  addShipping,
  addShippingDetail,
  delExprss,
  getExpress,
  getShippingList,
} from "../../axios/api";
export default {
  name: "Ship",
  setup() {
    const tableHeight = ref("0px");
    const statusOptions = [
      {
        value: "已支付",
        label: "已支付",
      },
      {
        value: "部分发货",
        label: "部分发货",
      },
      {
        value: "已审核",
        label: "已审核",
      },
      {
        value: "全部发货",
        label: "全部发货",
      },
    ];
    return {
      statusOptions,
      tableHeight,
      CirclePlusFilled,
      Brush,
      Search,
    };
  },
  data() {
    return {
      query: {
        id: "",
        order_num: "",
        buyer_name: "",
        order_price: "",
        created_at_start: "",
        created_at_end: "",
        order_status: "",
      },
      // 物流列表
      shipList: [],

      // 分页
      total: 0,
      pageNum: 1,
      pageSize: 50,

      // 空状态
      showEmpty: false,

      shipDialogFormVisible: false,
      isShip: true,
      allShip: "部分发货",
      editOrderID: 0,
      editOrderNum: 0,
      editAddress: "",
      // 物流管理表单
      shipForm: {
        order_id: 0,
        is_notice: 1,
        express_platform: 1,
        express_company: 1,
        odd_type: 1,
        express_num: "",
        remarks: "",
        send_lists: [],
      },
      orderData: [],
      comparativeData: [],
      shipData: [],

      // 弹窗tab
      activeName: "product",
    };
  },
  mounted() {
    this.getShipList();
    const extraHeight = document.querySelector(".query_form").offsetHeight;
    const mianHeight = document.querySelector(".main").offsetHeight;
    this.tableHeight = `${mianHeight - extraHeight - 181}px`;
  },
  methods: {
    // 重置表单
    resetForm() {
      this.$refs["query"].resetFields();
      // this.getShipList()
    },
    // 分页，处理函数
    handleCurrentChangePrev(val) {
      this.pageNum = val;
      console.log(`上一页: ${val}`);
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getOrderList();
      console.log(`当前页: ${val}`);
    },
    handleCurrentChangeNext(val) {
      this.pageNum = val;
      console.log(`下一页: ${val}`);
    },

    // 获取发货列表
    async getShipList() {
      try {
        this.query.page = this.pageNum;
        this.query.limit = this.pageSize;
        let params = this.query;
        const data = await getShippingList({ params });
        this.shipList = data.data;
        this.total = data.count;
        if (this.shipList.length === 0) {
          this.showEmpty = true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    shipDetails(index, row) {
      this.$router.push({
        path: "/order/details",
        query: { order_id: row.id },
      });
    },
    async shipMangage(index, row) {
      this.editOrderID = row.id;
      this.editOrderNum = row.order_num;
      this.shipForm.order_id = row.id;
      this.shipForm.send_lists = [];
      try {
        let params = { order_id: row.id };
        const data = await addShippingDetail({ params });
        const expressData = await getExpress({ params });
        this.editAddress = data.data.addrdetail;
        this.orderData = data.data.ordergoods;
        this.comparativeData = cloneDeep(data.data.ordergoods);
        this.shipData = expressData.data;
      } catch (error) {
        console.log(error);
      }
      this.shipDialogFormVisible = true;
    },

    handleClick(tab) {
      // 加载订单信息
      if (tab.paneName === "product") {
        console.log(tab.paneName);
      } else {
        console.log(tab.paneName);
      }
    },

    changeShipmentsNumber(index, row) {
      let total = 0;
      let send_total = 0;
      this.orderData.forEach((item) => {
        total += item.goods_num;
        send_total += item.send_num;
      });
      //修改发货数
      if (row.send_num !== this.comparativeData[index].send_num) {
        this.isShip = false;
        if (send_total < total) {
          this.allShip = "部分发货";
        } else {
          this.allShip = "全部发货";
        }
      } else {
        this.isShip = true;
        this.allShip = "部分发货";
      }
      if (this.shipForm.send_lists.length > 0) {
        let isExist = false;
        this.shipForm.send_lists.forEach((item) => {
          if (item.goods_id === row.goods_id) {
            isExist = true;
            item.send_num = row.send_num - this.comparativeData[index].send_num;
          }
        });
        if (!isExist) {
          this.shipForm.send_lists.push({
            goods_id: row.goods_id,
            send_num: row.send_num - this.comparativeData[index].send_num,
          });
        }
      } else {
        this.shipForm.send_lists.push({
          goods_id: row.goods_id,
          send_num: row.send_num - this.comparativeData[index].send_num,
        });
      }
    },

    async deleteShip(row) {
      try {
        await delExprss({ id: row.id });
        ElMessage({ message: "删除成功", type: "success" });
      } catch (error) {
        console.log(error);
      }
    },

    async addShip() {
      try {
        this.shipForm.send_lists = JSON.stringify(this.shipForm.send_lists);
        console.log(this.shipForm.send_lists);
        await addShipping(this.shipForm);
        ElMessage({ message: "添加成功", type: "success" });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-card:first-child {
  margin-bottom: 20px;
}

.text-center {
  text-align: center;
}

.el-alert {
  margin-bottom: 1.5rem;
  padding: 16px 10px;
}

.dialog-content {
  padding: 0 4rem;

  .image {
    width: 50px;
    height: 50px;
    border-radius: 5px;
  }

  .el-input-number {
    width: 100%;
  }
}
</style>
