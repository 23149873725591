import axios from "axios";
import { ElMessage } from 'element-plus';
import router from '../router/index';

axios.defaults.baseURL = "https://shop-api.w-superbox.com/api/v1/w-superbox/admin";

const request = axios.create({
    timeout: 5000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    }
});

request.interceptors.request.use(config => {
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
    return config
})

request.interceptors.response.use(response => {
    if (response.data.code === 200) {
        return response.data;
    } else {
        if (response.data.code === 405) {
            ElMessage.error("登录过期，请重新登录")
            localStorage.clear();
            sessionStorage.clear();
            router.push('/login');
        } if (response.data.code === 402) {
            ElMessage.error(response.data.msg)
            router.push('/login');
        } else {
            ElMessage.error(response.data.msg)
        }
        return Promise.reject(response)
    }
}, error => {
    // router.push('/404');
    return Promise.reject(error)
})

export default request;