<template>
  <div>
    <el-card style="margin-bottom: 20px">
      <el-form
        class="query-form"
        :inline="true"
        :model="productFormQuery"
        ref="formRef"
      >
        <el-form-item label="产品名" prop="product_name">
          <el-input v-model="productFormQuery.product_name" />
        </el-form-item>
        <el-form-item label="盲盒分类" prop="brand_id">
          <el-cascader
            v-model="productFormQuery.brand_id"
            :options="categoryOption"
            placeholder="请选择"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :icon="Search" @click="getProList"
            >查询</el-button
          >
          <el-button type="primary" :icon="Brush" @click="resetForm" />
          <el-button type="primary" :icon="Plus" @click="addProduct"
            >添加盲盒产品</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table :height="tableHeight" :data="productsList" style="width: 100%">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" sortable width="70" />
        <el-table-column prop="thumb" label="图片">
          <template #default="scope">
            <div style="display: inline-flex">
              <el-image class="goods_image" :src="scope.row.thumb" />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="box_goods_name"
          label="产品名称"
          min-width="200"
          sortable
        />
        <!-- <el-table-column prop="cost_price" label="产品原价" /> -->
        <el-table-column prop="is_surprise" label="款式">
          <template #default="scope">
            <el-tag v-if="scope.row.is_surprise === 1" type="success"
              >惊喜款</el-tag
            >
            <el-tag v-if="scope.row.is_surprise === 2">超值款</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="rate" label="中奖率" />
        <el-table-column prop="number" label="数量" /> -->
        <el-table-column fixed="right" label=" 操作" width="140">
          <template #default="scope">
            <el-button
              :icon="Edit"
              @click="editProduct(scope.$index, scope.row)"
            />
            <el-popconfirm
              title="此操作将删除该产品数据, 是否继续?"
              confirmButtonText="确认"
              cancelButtonText="取消"
              cancelButtonType="default"
              :icon="WarningFilled"
              @confirm="deleteProduct(scope.row)"
            >
              <template #reference>
                <el-button type="danger" :icon="Delete" />
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding-top: 15px">
        <el-pagination
          layout="total, prev, pager, next"
          :current-page="pageData.pageNum"
          :page-size="pageData.pageSize"
          :total="pageData.total"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChangePrev"
          @next-click="handleCurrentChangeNext"
          background
        />
      </div>
    </el-card>
  </div>
</template>
<script>
import {
  deleteBlindBoxProduct,
  getBlindBoxCategory,
  getBlindBoxProducts,
} from "@/axios/api";
import {
  Brush,
  Delete,
  Edit,
  Plus,
  Search,
  WarningFilled,
} from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "blindBoxesProducts",
  setup() {
    const router = useRouter();
    const pageData = ref({
      total: 0,
      pageNum: 1,
      pageSize: 50,
    });
    const productFormQuery = ref({
      product_name: "",
      brand_id: "",
    });
    const categoryOption = ref([]);
    const productsList = ref([]);

    // 重置表单
    const formRef = ref();
    const resetForm = () => {
      formRef.value.resetFields();
      getProList();
    };
    const addProduct = () => {
      router.push("/blindBoxes/products/add");
    };
    // 分页，处理函数
    const handleCurrentChangePrev = (val) => {
      pageData.value.pageNum = val;
    };
    const handleCurrentChange = (val) => {
      pageData.value.pageNum = val;
      getProList();
    };
    const handleCurrentChangeNext = (val) => {
      pageData.value.pageNum = val;
    };

    getCategoryOption();
    async function getCategoryOption() {
      try {
        const data = await getBlindBoxCategory();
        let options = [];
        data.data.forEach((element) => {
          options.push({ value: element.id, label: element.brand_name });
          categoryOption.value = options;
        });
      } catch (error) {
        console.log(error);
      }
    }
    getProList();
    async function getProList() {
      try {
        let params = {
          product_name: productFormQuery.value.product_name,
          brand_id: productFormQuery.value.brand_id,
          page: pageData.value.pageNum,
          limit: pageData.value.pageSize,
        };
        const data = await getBlindBoxProducts({ params });
        productsList.value = data.data;
        pageData.value.total = data.data.length;
      } catch (error) {
        console.log(error);
      }
    }

    const editProduct = (index, item) => {
      router.push({
        path: "/blindBoxes/products/edit",
        query: { product_id: item.id },
      });
    };

    async function deleteProduct(item) {
      try {
        await deleteBlindBoxProduct({ product_id: item.id });
        ElMessage({ message: "删除成功", type: "success" });
        this.getProList();
      } catch (error) {
        console.log(error);
      }
    }

    const tableHeight = ref("0px");
    onMounted(() => {
      const extraHeight = document.querySelector(".query-form").offsetHeight;
      tableHeight.value = `calc(100vh - ` + (extraHeight + 270) + `px)`;
    });
    return {
      Brush,
      Plus,
      Search,
      Delete,
      WarningFilled,
      Edit,
      formRef,
      pageData,
      categoryOption,
      productFormQuery,
      productsList,
      tableHeight,
      addProduct,
      resetForm,
      getCategoryOption,
      getProList,
      handleCurrentChangePrev,
      handleCurrentChange,
      handleCurrentChangeNext,
      deleteProduct,
      editProduct,
    };
  },
};
</script>
<style lang="less" scoped>
.goods_image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
</style>
