<template>
  <div>
    <el-card style="margin-bottom: 20px">
      <el-form :inline="true">
        <el-form-item label="分类名称"
          ><el-input v-model="queryName" placeholder="盲盒分类"
        /></el-form-item>
        <el-form-item
          ><el-button type="primary" :icon="Search" @click="getData"
            >查询</el-button
          ></el-form-item
        >
        <el-form-item
          ><el-button type="primary" @click="openAddDialog"
            >添加盲盒分类</el-button
          ></el-form-item
        >
      </el-form>
    </el-card>
    <el-table :data="categoryList" height="65vh" style="width: 100%">
      <el-table-column type="selection" width="55" />
      <el-table-column prop="id" label="编号" sortable />
      <el-table-column prop="brand_name" label="分类名称" sortable />
      <el-table-column prop="brand_price" label="分类价格" />
      <el-table-column prop="brand_status" label="分类状态">
        <template #default="scope">
          <el-tag v-if="scope.row.brand_status === 1" type="success"
            >启用</el-tag
          >
          <el-tag v-if="scope.row.brand_status === 2" type="danger"
            >禁用</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="get_surprise_times" label="惊喜款次数" />
      <el-table-column prop="count_times" label="总轮次数" />
      <el-table-column label="操作" fixed="right" width="140">
        <template #default="scope">
          <el-button
            type="primary"
            :icon="Edit"
            @click="openEditDialog(scope.row)"
          />
          <el-popconfirm
            title="此操作将永久删除该信息, 是否继续?"
            confirmButtonText="确认"
            cancelButtonText="取消"
            cancelButtonType="default"
            :icon="WarningFilled"
            @confirm="deleteCategory(scope.row)"
          >
            <template #reference>
              <el-button type="danger" :icon="Delete" />
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
      <template #empty>
        <div style="margin: 50px 0">
          <el-empty
            v-if="categoryList.length === 0"
            description="暂时还没有数据哦"
          />
        </div>
      </template>
    </el-table>
    <el-dialog
      :title="dialogTitle"
      v-model="categoryDialogVisible"
      center
      width="50%"
    >
      <el-form :model="category_form" label-width="120px" style="padding: 10px">
        <el-form-item label="分类名称">
          <el-input v-model="category_form.brand_name" type="text" />
        </el-form-item>
        <el-form-item label="分类价格">
          <el-input v-model.number="category_form.brand_price" />
        </el-form-item>
        <el-form-item label="分类状态">
          <el-select v-model="category_form.brand_status" placeholder="Select">
            <el-option :value="1" label="启用" />
            <el-option :value="2" label="禁用" />
          </el-select>
        </el-form-item>
        <el-form-item label="惊喜款次数">
          <el-input v-model.number="category_form.get_surprise_times" />
        </el-form-item>
        <el-form-item label="总轮次数">
          <el-input v-model.number="category_form.count_times" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="categoryDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmCategory">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import {
  addBlindBoxCategory,
  changeBlindBoxCategory,
  deleteBlindBoxCategory,
  getBlindBoxCategory,
} from "@/axios/api";
import { Delete, Edit, Search, WarningFilled } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { ref } from "vue";
export default {
  name: "blindBoxesCategory",
  components: {},
  setup() {
    const categoryList = ref([]);
    const categoryDialogVisible = ref(false);
    const category_form = ref({});
    const dialogTitle = ref("");
    const operateType = ref("");
    const queryName = ref("");
    getData();
    async function getData() {
      try {
        let params = {
          brand_name: queryName.value,
        };
        const data = await getBlindBoxCategory({ params });
        categoryList.value = data.data;
      } catch (error) {
        console.log(error);
      }
    }

    const openEditDialog = (item) => {
      operateType.value = "edit";
      dialogTitle.value = "编辑盲盒分类";
      category_form.value = item;
      categoryDialogVisible.value = true;
    };

    const openAddDialog = () => {
      operateType.value = "add";
      dialogTitle.value = "添加盲盒分类";
      category_form.value = {
        brand_name: "",
        brand_price: 0,
        brand_status: 1,
        get_surprise_times: 0,
        count_times: 0,
      };
      categoryDialogVisible.value = true;
    };

    async function confirmCategory() {
      if (operateType.value === "edit") {
        try {
          await changeBlindBoxCategory({
            brand_id: category_form.value.id,
            brand_type: category_form.value.brand_type,
            brand_name: category_form.value.brand_name,
            brand_price: category_form.value.brand_price,
            brand_status: category_form.value.brand_status,
            get_surprise_times: category_form.value.get_surprise_times,
            count_times: category_form.value.count_times,
          });
          ElMessage({ message: "修改成功", type: "success" });
          categoryDialogVisible.value = false;
          getData();
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await addBlindBoxCategory({
            brand_type: 1,
            brand_name: category_form.value.brand_name,
            brand_price: category_form.value.brand_price,
            brand_status: category_form.value.brand_status,
            get_surprise_times: category_form.value.get_surprise_times,
            count_times: category_form.value.count_times,
          });
          ElMessage({ message: "修改成功", type: "success" });
          categoryDialogVisible.value = false;
          getData();
        } catch (error) {
          console.log(error);
        }
      }
    }

    async function deleteCategory(row) {
      try {
        await deleteBlindBoxCategory({ brand_id: row.id });
        ElMessage({ message: "删除成功", type: "success" });
        getData();
      } catch (error) {
        console.log(error);
      }
    }
    return {
      Search,
      Edit,
      Delete,
      WarningFilled,

      queryName,
      dialogTitle,
      categoryList,
      categoryDialogVisible,
      category_form,

      openAddDialog,
      openEditDialog,
      confirmCategory,
      deleteCategory,
      getData,
    };
  },
};
</script>
<style lang="less" scoped></style>
