/*省市区三级联动*/
let options = [
  { id: 0, country: 'Afghanistan', zones: [] },
  { id: 1, country: 'Åland Islands', zones: [] },
  { id: 2, country: 'Albania', zones: [] },
  { id: 3, country: 'Algeria', zones: [] },
  { id: 4, country: 'Andorra', zones: [] },
  { id: 5, country: 'Angola', zones: [] },
  { id: 6, country: 'Anguilla', zones: [] },
  { id: 7, country: 'Antigua & Barbuda', zones: [] },
  {
    id: 8,
    country: 'Argentina',
    zones: [
      'Buenos Aires Province',
      'Catamarca',
      'Chaco',
      'Chubut',
      'Buenos Aires (Autonomous City)',
      'Córdoba',
      'Corrientes',
      'Entre Ríos',
      'Formosa',
      'Jujuy',
      'La Pampa',
      'La Rioja',
      'Mendoza',
      'Misiones',
      'Neuquén',
      'Río Negro',
      'Salta',
      'San Juan',
      'San Luis',
      'Santa Cruz',
      'Santa Fe',
      'Santiago del Estero',
      'Tierra del Fuego',
      'Tucumán'
    ]
  },
  { id: 9, country: 'Armenia', zones: [] },
  { id: 10, country: 'Aruba', zones: [] },
  { id: 11, country: 'Ascension Island', zones: [] },
  {
    id: 12,
    country: 'Australia',
    zones: [
      'Australian Capital Territory',
      'New South Wales',
      'Northern Territory',
      'Queensland',
      'South Australia',
      'Tasmania',
      'Victoria',
      'Western Australia'
    ]
  },
  { id: 13, country: 'Austria', zones: [] },
  { id: 14, country: 'Azerbaijan', zones: [] },
  { id: 15, country: 'Bahamas', zones: [] },
  { id: 16, country: 'Bahrain', zones: [] },
  { id: 17, country: 'Bangladesh', zones: [] },
  { id: 18, country: 'Barbados', zones: [] },
  { id: 19, country: 'Belarus', zones: [] },
  { id: 20, country: 'Belgium', zones: [] },
  { id: 21, country: 'Belize', zones: [] },
  { id: 22, country: 'Benin', zones: [] },
  { id: 23, country: 'Bermuda', zones: [] },
  { id: 24, country: 'Bhutan', zones: [] },
  { id: 25, country: 'Bolivia', zones: [] },
  { id: 26, country: 'Bosnia & Herzegovina', zones: [] },
  { id: 27, country: 'Botswana', zones: [] },
  {
    id: 28,
    country: 'Brazil',
    zones: [
      'Acre', 'Alagoas',
      'Amapá', 'Amazonas',
      'Bahia', 'Ceará',
      'Federal District', 'Espírito Santo',
      'Goiás', 'Maranhão',
      'Mato Grosso', 'Mato Grosso do Sul',
      'Minas Gerais', 'Pará',
      'Paraíba', 'Paraná',
      'Pernambuco', 'Piauí',
      'Rio Grande do Norte', 'Rio Grande do Sul',
      'Rio de Janeiro', 'Rondônia',
      'Roraima', 'Santa Catarina',
      'São Paulo', 'Sergipe',
      'Tocantins'
    ]
  },
  { id: 29, country: 'British Indian Ocean Territory', zones: [] },
  { id: 30, country: 'British Virgin Islands', zones: [] },
  { id: 31, country: 'Brunei', zones: [] },
  { id: 32, country: 'Bulgaria', zones: [] },
  { id: 33, country: 'Burkina Faso', zones: [] },
  { id: 34, country: 'Burundi', zones: [] },
  { id: 35, country: 'Cambodia', zones: [] },
  { id: 36, country: 'Cameroon', zones: [] },
  {
    id: 37,
    country: 'Canada',
    zones: [
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland and Labrador',
      'Northwest Territories',
      'Nova Scotia',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Quebec',
      'Saskatchewan',
      'Yukon'
    ]
  },
  { id: 38, country: 'Cape Verde', zones: [] },
  { id: 39, country: 'Caribbean Netherlands', zones: [] },
  { id: 40, country: 'Cayman Islands', zones: [] },
  { id: 41, country: 'Central African Republic', zones: [] },
  { id: 42, country: 'Chad', zones: [] },
  {
    id: 43,
    country: 'Chile',
    zones: [
      'Arica y Parinacota',
      'Tarapacá',
      'Antofagasta',
      'Atacama',
      'Coquimbo',
      'Valparaíso',
      'Santiago Metropolitan',
      'Libertador General Bernardo O’Higgins',
      'Maule',
      'Ñuble',
      'Bío Bío',
      'Araucanía',
      'Los Ríos',
      'Los Lagos',
      'Aysén',
      'Magallanes Region'
    ]
  },
  {
    id: 44,
    country: 'China',
    zones: [
      'Anhui', 'Beijing', 'Chongqing',
      'Fujian', 'Gansu', 'Guangdong',
      'Guangxi', 'Guizhou', 'Hainan',
      'Hebei', 'Heilongjiang', 'Henan',
      'Hubei', 'Hunan', 'Inner Mongolia',
      'Jiangsu', 'Jiangxi', 'Jilin',
      'Liaoning', 'Ningxia', 'Qinghai',
      'Shaanxi', 'Shandong', 'Shanghai',
      'Shanxi', 'Sichuan', 'Tianjin',
      'Xinjiang', 'Tibet', 'Yunnan',
      'Zhejiang'
    ]
  },
  { id: 45, country: 'Christmas Island', zones: [] },
  { id: 46, country: 'Cocos (Keeling) Islands', zones: [] },
  {
    id: 47,
    country: 'Colombia',
    zones: [
      'Capital District',
      'Amazonas',
      'Antioquia',
      'Arauca',
      'Atlántico',
      'Bolívar',
      'Boyacá',
      'Caldas',
      'Caquetá',
      'Casanare',
      'Cauca',
      'Cesar',
      'Chocó',
      'Córdoba',
      'Cundinamarca',
      'Guainía',
      'Guaviare',
      'Huila',
      'La Guajira',
      'Magdalena',
      'Meta',
      'Nariño',
      'Norte de Santander',
      'Putumayo',
      'Quindío',
      'Risaralda',
      'San Andrés & Providencia',
      'Santander',
      'Sucre',
      'Tolima',
      'Valle del Cauca',
      'Vaupés',
      'Vichada'
    ]
  },
  { id: 48, country: 'Comoros', zones: [] },
  { id: 49, country: 'Congo - Brazzaville', zones: [] },
  { id: 50, country: 'Congo - Kinshasa', zones: [] },
  { id: 51, country: 'Cook Islands', zones: [] },
  {
    id: 52,
    country: 'Costa Rica',
    zones: [
      'Alajuela',
      'Cartago',
      'Guanacaste',
      'Heredia',
      'Limón',
      'Puntarenas',
      'San José'
    ]
  },
  { id: 53, country: 'Croatia', zones: [] },
  { id: 54, country: 'Curaçao', zones: [] },
  { id: 55, country: 'Cyprus', zones: [] },
  { id: 56, country: 'Czechia', zones: [] },
  { id: 57, country: 'Côte d’Ivoire', zones: [] },
  { id: 58, country: 'Denmark', zones: [] },
  { id: 59, country: 'Djibouti', zones: [] },
  { id: 60, country: 'Dominica', zones: [] },
  { id: 61, country: 'Dominican Republic', zones: [] },
  { id: 62, country: 'Ecuador', zones: [] },
  {
    id: 63,
    country: 'Egypt',
    zones: [
      '6th of October', 'Al Sharqia',
      'Alexandria', 'Aswan',
      'Asyut', 'Beheira',
      'Beni Suef', 'Cairo',
      'Dakahlia', 'Damietta',
      'Faiyum', 'Gharbia',
      'Giza', 'Helwan',
      'Ismailia', 'Kafr el-Sheikh',
      'Luxor', 'Matrouh',
      'Minya', 'Monufia',
      'New Valley', 'North Sinai',
      'Port Said', 'Qalyubia',
      'Qena', 'Red Sea',
      'Sohag', 'South Sinai',
      'Suez'
    ]
  },
  {
    id: 64,
    country: 'El Salvador',
    zones: [
      'Ahuachapán', 'Cabañas',
      'Chalatenango', 'Cuscatlán',
      'La Libertad', 'La Paz',
      'La Unión', 'Morazán',
      'San Miguel', 'San Salvador',
      'San Vicente', 'Santa Ana',
      'Sonsonate', 'Usulután'
    ]
  },
  { id: 65, country: 'Equatorial Guinea', zones: [] },
  { id: 66, country: 'Eritrea', zones: [] },
  { id: 67, country: 'Estonia', zones: [] },
  { id: 68, country: 'Eswatini', zones: [] },
  { id: 69, country: 'Ethiopia', zones: [] },
  { id: 70, country: 'Falkland Islands', zones: [] },
  { id: 71, country: 'Faroe Islands', zones: [] },
  { id: 72, country: 'Fiji', zones: [] },
  { id: 73, country: 'Finland', zones: [] },
  { id: 74, country: 'France', zones: [] },
  { id: 75, country: 'French Guiana', zones: [] },
  { id: 76, country: 'French Polynesia', zones: [] },
  { id: 77, country: 'French Southern Territories', zones: [] },
  { id: 78, country: 'Gabon', zones: [] },
  { id: 79, country: 'Gambia', zones: [] },
  { id: 80, country: 'Georgia', zones: [] },
  { id: 81, country: 'Germany', zones: [] },
  { id: 82, country: 'Ghana', zones: [] },
  { id: 83, country: 'Gibraltar', zones: [] },
  { id: 84, country: 'Greece', zones: [] },
  { id: 85, country: 'Greenland', zones: [] },
  { id: 86, country: 'Grenada', zones: [] },
  { id: 87, country: 'Guadeloupe', zones: [] },
  {
    id: 88,
    country: 'Guatemala',
    zones: [
      'Alta Verapaz', 'Baja Verapaz',
      'Chimaltenango', 'Chiquimula',
      'El Progreso', 'Escuintla',
      'Guatemala', 'Huehuetenango',
      'Izabal', 'Jalapa',
      'Jutiapa', 'Petén',
      'Quetzaltenango', 'Quiché',
      'Retalhuleu', 'Sacatepéquez',
      'San Marcos', 'Santa Rosa',
      'Sololá', 'Suchitepéquez',
      'Totonicapán', 'Zacapa'
    ]
  },
  { id: 89, country: 'Guernsey', zones: [] },
  { id: 90, country: 'Guinea', zones: [] },
  { id: 91, country: 'Guinea-Bissau', zones: [] },
  { id: 92, country: 'Guyana', zones: [] },
  { id: 93, country: 'Haiti', zones: [] },
  { id: 94, country: 'Honduras', zones: [] },
  {
    id: 95,
    country: 'Hong Kong SAR',
    zones: ['Hong Kong Island', 'Kowloon', 'New Territories']
  },
  { id: 96, country: 'Hungary', zones: [] },
  { id: 97, country: 'Iceland', zones: [] },
  {
    id: 98,
    country: 'India',
    zones: [
      'Andaman and Nicobar Islands',
      'Andhra Pradesh',
      'Arunachal Pradesh',
      'Assam',
      'Bihar',
      'Chandigarh',
      'Chhattisgarh',
      'Dadra and Nagar Haveli',
      'Daman and Diu',
      'Delhi',
      'Goa',
      'Gujarat',
      'Haryana',
      'Himachal Pradesh',
      'Jammu and Kashmir',
      'Jharkhand',
      'Karnataka',
      'Kerala',
      'Ladakh',
      'Lakshadweep',
      'Madhya Pradesh',
      'Maharashtra',
      'Manipur',
      'Meghalaya',
      'Mizoram',
      'Nagaland',
      'Odisha',
      'Puducherry',
      'Punjab',
      'Rajasthan',
      'Sikkim',
      'Tamil Nadu',
      'Telangana',
      'Tripura',
      'Uttar Pradesh',
      'Uttarakhand',
      'West Bengal'
    ]
  },
  {
    id: 99,
    country: 'Indonesia',
    zones: [
      'Aceh',
      'Bali',
      'Bangka–Belitung Islands',
      'Banten',
      'Bengkulu',
      'Gorontalo',
      'Jakarta',
      'Jambi',
      'West Java',
      'Central Java',
      'East Java',
      'West Kalimantan',
      'South Kalimantan',
      'Central Kalimantan',
      'East Kalimantan',
      'North Kalimantan',
      'Riau Islands',
      'Lampung',
      'Maluku',
      'North Maluku',
      'North Sumatra',
      'West Nusa Tenggara',
      'East Nusa Tenggara',
      'Papua',
      'West Papua',
      'Riau',
      'South Sumatra',
      'West Sulawesi',
      'South Sulawesi',
      'Central Sulawesi',
      'Southeast Sulawesi',
      'North Sulawesi',
      'West Sumatra',
      'Yogyakarta'
    ]
  },
  { id: 100, country: 'Iraq', zones: [] },
  {
    id: 101,
    country: 'Ireland',
    zones: [
      'Carlow', 'Cavan', 'Clare',
      'Cork', 'Donegal', 'Dublin',
      'Galway', 'Kerry', 'Kildare',
      'Kilkenny', 'Laois', 'Leitrim',
      'Limerick', 'Longford', 'Louth',
      'Mayo', 'Meath', 'Monaghan',
      'Offaly', 'Roscommon', 'Sligo',
      'Tipperary', 'Waterford', 'Westmeath',
      'Wexford', 'Wicklow'
    ]
  },
  { id: 102, country: 'Isle of Man', zones: [] },
  { id: 103, country: 'Israel', zones: [] },
  {
    id: 104,
    country: 'Italy',
    zones: [
      'Agrigento', 'Alessandria', 'Ancona',
      'Aosta Valley', 'Arezzo', 'Ascoli Piceno',
      'Asti', 'Avellino', 'Bari',
      'Barletta-Andria-Trani', 'Belluno', 'Benevento',
      'Bergamo', 'Biella', 'Bologna',
      'South Tyrol', 'Brescia', 'Brindisi',
      'Cagliari', 'Caltanissetta', 'Campobasso',
      'Carbonia-Iglesias', 'Caserta', 'Catania',
      'Catanzaro', 'Chieti', 'Como',
      'Cosenza', 'Cremona', 'Crotone',
      'Cuneo', 'Enna', 'Fermo',
      'Ferrara', 'Florence', 'Foggia',
      'Forlì-Cesena', 'Frosinone', 'Genoa',
      'Gorizia', 'Grosseto', 'Imperia',
      'Isernia', 'L’Aquila', 'La Spezia',
      'Latina', 'Lecce', 'Lecco',
      'Livorno', 'Lodi', 'Lucca',
      'Macerata', 'Mantua', 'Massa and Carrara',
      'Matera', 'Medio Campidano', 'Messina',
      'Milan', 'Modena', 'Monza and Brianza',
      'Naples', 'Novara', 'Nuoro',
      'Ogliastra', 'Olbia-Tempio', 'Oristano',
      'Padua', 'Palermo', 'Parma',
      'Pavia', 'Perugia', 'Pesaro and Urbino',
      'Pescara', 'Piacenza', 'Pisa',
      'Pistoia', 'Pordenone', 'Potenza',
      'Prato', 'Ragusa', 'Ravenna',
      'Reggio Calabria', 'Reggio Emilia', 'Rieti',
      'Rimini', 'Rome', 'Rovigo',
      'Salerno', 'Sassari', 'Savona',
      'Siena', 'Syracuse', 'Sondrio',
      'Taranto', 'Teramo', 'Terni',
      'Turin', 'Trapani', 'Trentino',
      'Treviso', 'Trieste',
      'Udine',
      'Varese',
      'Venice',
      'Verbano-Cusio-Ossola',
      'Vercelli',
      'Verona',
      'Vibo Valentia',
      'Vicenza',
      'Viterbo'
    ]
  },
  { id: 105, country: 'Jamaica', zones: [] },
  {
    id: 106,
    country: 'Japan',
    zones: [
      'Hokkaido', 'Aomori', 'Iwate', 'Miyagi',
      'Akita', 'Yamagata', 'Fukushima', 'Ibaraki',
      'Tochigi', 'Gunma', 'Saitama', 'Chiba',
      'Tokyo', 'Kanagawa', 'Niigata', 'Toyama',
      'Ishikawa', 'Fukui', 'Yamanashi', 'Nagano',
      'Gifu', 'Shizuoka', 'Aichi', 'Mie',
      'Shiga', 'Kyoto', 'Osaka', 'Hyogo',
      'Nara', 'Wakayama', 'Tottori', 'Shimane',
      'Okayama', 'Hiroshima', 'Yamaguchi', 'Tokushima',
      'Kagawa', 'Ehime', 'Kochi', 'Fukuoka',
      'Saga', 'Nagasaki', 'Kumamoto', 'Oita',
      'Miyazaki', 'Kagoshima', 'Okinawa'
    ]
  },
  { id: 107, country: 'Jersey', zones: [] },
  { id: 108, country: 'Jordan', zones: [] },
  { id: 109, country: 'Kazakhstan', zones: [] },
  { id: 110, country: 'Kenya', zones: [] },
  { id: 111, country: 'Kiribati', zones: [] },
  { id: 112, country: 'Kosovo', zones: [] },
  { id: 113, country: 'Kuwait', zones: [] },
  { id: 114, country: 'Kyrgyzstan', zones: [] },
  { id: 115, country: 'Laos', zones: [] },
  { id: 116, country: 'Latvia', zones: [] },
  { id: 117, country: 'Lebanon', zones: [] },
  { id: 118, country: 'Lesotho', zones: [] },
  { id: 119, country: 'Liberia', zones: [] },
  { id: 120, country: 'Libya', zones: [] },
  { id: 121, country: 'Liechtenstein', zones: [] },
  { id: 122, country: 'Lithuania', zones: [] },
  { id: 123, country: 'Luxembourg', zones: [] },
  { id: 124, country: 'Macao SAR', zones: [] },
  { id: 125, country: 'Madagascar', zones: [] },
  { id: 126, country: 'Malawi', zones: [] },
  {
    id: 127,
    country: 'Malaysia',
    zones: [
      'Johor', 'Kedah',
      'Kelantan', 'Kuala Lumpur',
      'Labuan', 'Malacca',
      'Negeri Sembilan', 'Pahang',
      'Penang', 'Perak',
      'Perlis', 'Putrajaya',
      'Sabah', 'Sarawak',
      'Selangor', 'Terengganu'
    ]
  },
  { id: 128, country: 'Maldives', zones: [] },
  { id: 129, country: 'Mali', zones: [] },
  { id: 130, country: 'Malta', zones: [] },
  { id: 131, country: 'Martinique', zones: [] },
  { id: 132, country: 'Mauritania', zones: [] },
  { id: 133, country: 'Mauritius', zones: [] },
  { id: 134, country: 'Mayotte', zones: [] },
  {
    id: 135,
    country: 'Mexico',
    zones: [
      'Aguascalientes', 'Baja California',
      'Baja California Sur', 'Campeche',
      'Chiapas', 'Chihuahua',
      'Ciudad de Mexico', 'Coahuila',
      'Colima', 'Durango',
      'Guanajuato', 'Guerrero',
      'Hidalgo', 'Jalisco',
      'Mexico State', 'Michoacán',
      'Morelos', 'Nayarit',
      'Nuevo León', 'Oaxaca',
      'Puebla', 'Querétaro',
      'Quintana Roo', 'San Luis Potosí',
      'Sinaloa', 'Sonora',
      'Tabasco', 'Tamaulipas',
      'Tlaxcala', 'Veracruz',
      'Yucatán', 'Zacatecas'
    ]
  },
  { id: 136, country: 'Moldova', zones: [] },
  { id: 137, country: 'Monaco', zones: [] },
  { id: 138, country: 'Mongolia', zones: [] },
  { id: 139, country: 'Montenegro', zones: [] },
  { id: 140, country: 'Montserrat', zones: [] },
  { id: 141, country: 'Morocco', zones: [] },
  { id: 142, country: 'Mozambique', zones: [] },
  { id: 143, country: 'Myanmar (Burma)', zones: [] },
  { id: 144, country: 'Namibia', zones: [] },
  { id: 145, country: 'Nauru', zones: [] },
  { id: 146, country: 'Nepal', zones: [] },
  { id: 147, country: 'Netherlands', zones: [] },
  { id: 148, country: 'New Caledonia', zones: [] },
  {
    id: 149,
    country: 'New Zealand',
    zones: [
      'Auckland', 'Bay of Plenty',
      'Canterbury', 'Chatham Islands',
      'Gisborne', 'Hawke’s Bay',
      'Manawatū-Whanganui', 'Marlborough',
      'Nelson', 'Northland',
      'Otago', 'Southland',
      'Taranaki', 'Tasman',
      'Waikato', 'Wellington',
      'West Coast'
    ]
  },
  { id: 150, country: 'Nicaragua', zones: [] },
  { id: 151, country: 'Niger', zones: [] },
  {
    id: 152,
    country: 'Nigeria',
    zones: [
      'Abia',
      'Federal Capital Territory',
      'Adamawa',
      'Akwa Ibom',
      'Anambra',
      'Bauchi',
      'Bayelsa',
      'Benue',
      'Borno',
      'Cross River',
      'Delta',
      'Ebonyi',
      'Edo',
      'Ekiti',
      'Enugu',
      'Gombe',
      'Imo',
      'Jigawa',
      'Kaduna',
      'Kano',
      'Katsina',
      'Kebbi',
      'Kogi',
      'Kwara',
      'Lagos',
      'Nasarawa',
      'Niger',
      'Ogun',
      'Ondo',
      'Osun',
      'Oyo',
      'Plateau',
      'Rivers',
      'Sokoto',
      'Taraba',
      'Yobe',
      'Zamfara'
    ]
  },
  { id: 153, country: 'Niue', zones: [] },
  { id: 154, country: 'Norfolk Island', zones: [] },
  { id: 155, country: 'North Macedonia', zones: [] },
  { id: 156, country: 'Norway', zones: [] },
  { id: 157, country: 'Oman', zones: [] },
  { id: 158, country: 'Pakistan', zones: [] },
  { id: 159, country: 'Palestinian Territories', zones: [] },
  {
    id: 160,
    country: 'Panama',
    zones: [
      'Bocas del Toro', 'Chiriquí',
      'Coclé', 'Colón',
      'Darién', 'Emberá',
      'Herrera', 'Guna Yala',
      'Los Santos', 'Ngöbe-Buglé',
      'Panamá', 'West Panamá',
      'Veraguas'
    ]
  },
  { id: 161, country: 'Papua New Guinea', zones: [] },
  { id: 162, country: 'Paraguay', zones: [] },
  {
    id: 163,
    country: 'Peru',
    zones: [
      'Amazonas', 'Ancash',
      'Apurímac', 'Arequipa',
      'Ayacucho', 'Cajamarca',
      'El Callao', 'Cusco',
      'Huancavelica', 'Huánuco',
      'Ica', 'Junín',
      'La Libertad', 'Lambayeque',
      'Lima (Department)', 'Lima (Metropolitan)',
      'Loreto', 'Madre de Dios',
      'Moquegua', 'Pasco',
      'Piura', 'Puno',
      'San Martín', 'Tacna',
      'Tumbes', 'Ucayali'
    ]
  },
  {
    id: 164,
    country: 'Philippines',
    zones: [
      'Abra', 'Agusan del Norte', 'Agusan del Sur',
      'Aklan', 'Albay', 'Antique',
      'Apayao', 'Aurora', 'Basilan',
      'Bataan', 'Batanes', 'Batangas',
      'Benguet', 'Biliran', 'Bohol',
      'Bukidnon', 'Bulacan', 'Cagayan',
      'Camarines Norte', 'Camarines Sur', 'Camiguin',
      'Capiz', 'Catanduanes', 'Cavite',
      'Cebu', 'Cotabato', 'Davao Occidental',
      'Davao Oriental', 'Compostela Valley', 'Davao del Norte',
      'Davao del Sur', 'Dinagat Islands', 'Eastern Samar',
      'Guimaras', 'Ifugao', 'Ilocos Norte',
      'Ilocos Sur', 'Iloilo', 'Isabela',
      'Kalinga', 'La Union', 'Laguna',
      'Lanao del Norte', 'Lanao del Sur', 'Leyte',
      'Maguindanao', 'Marinduque', 'Masbate',
      'Metro Manila', 'Misamis Occidental', 'Misamis Oriental',
      'Mountain', 'Negros Occidental', 'Negros Oriental',
      'Northern Samar', 'Nueva Ecija', 'Nueva Vizcaya',
      'Occidental Mindoro', 'Oriental Mindoro', 'Palawan',
      'Pampanga', 'Pangasinan', 'Quezon',
      'Quirino', 'Rizal', 'Romblon',
      'Samar', 'Sarangani', 'Siquijor',
      'Sorsogon', 'South Cotabato', 'Southern Leyte',
      'Sultan Kudarat', 'Sulu', 'Surigao del Norte',
      'Surigao del Sur', 'Tarlac', 'Tawi-Tawi',
      'Zambales', 'Zamboanga Sibugay', 'Zamboanga del Norte',
      'Zamboanga del Sur'
    ]
  },
  { id: 165, country: 'Pitcairn Islands', zones: [] },
  { id: 166, country: 'Poland', zones: [] },
  {
    id: 167,
    country: 'Portugal',
    zones: [
      'Azores', 'Aveiro',
      'Beja', 'Braga',
      'Bragança', 'Castelo Branco',
      'Coimbra', 'Évora',
      'Faro', 'Guarda',
      'Leiria', 'Lisbon',
      'Madeira', 'Portalegre',
      'Porto', 'Santarém',
      'Setúbal', 'Viana do Castelo',
      'Vila Real', 'Viseu'
    ]
  },
  { id: 168, country: 'Qatar', zones: [] },
  { id: 169, country: 'Réunion', zones: [] },
  {
    id: 170,
    country: 'Romania',
    zones: [
      'Alba', 'Arad', 'Argeș',
      'Bacău', 'Bihor', 'Bistriţa-Năsăud',
      'Botoşani', 'Brăila', 'Braşov',
      'Bucharest', 'Buzău', 'Caraș-Severin',
      'Cluj', 'Constanța', 'Covasna',
      'Călărași', 'Dolj', 'Dâmbovița',
      'Galați', 'Giurgiu', 'Gorj',
      'Harghita', 'Hunedoara', 'Ialomița',
      'Iași', 'Ilfov', 'Maramureş',
      'Mehedinți', 'Mureş', 'Neamţ',
      'Olt', 'Prahova', 'Sălaj',
      'Satu Mare', 'Sibiu', 'Suceava',
      'Teleorman', 'Timiș', 'Tulcea',
      'Vâlcea', 'Vaslui', 'Vrancea'
    ]
  },
  {
    id: 171,
    country: 'Russia',
    zones: [
      'Altai Krai', 'Altai', 'Amur',
      'Arkhangelsk', 'Astrakhan', 'Belgorod',
      'Bryansk', 'Chechen', 'Chelyabinsk',
      'Chukotka Okrug', 'Chuvash', 'Irkutsk',
      'Ivanovo', 'Jewish', 'Kabardino-Balkar',
      'Kaliningrad', 'Kaluga', 'Kamchatka Krai',
      'Karachay-Cherkess', 'Kemerovo', 'Khabarovsk Krai',
      'Khanty-Mansi', 'Kirov', 'Komi',
      'Kostroma', 'Krasnodar Krai', 'Krasnoyarsk Krai',
      'Kurgan', 'Kursk', 'Leningrad',
      'Lipetsk', 'Magadan', 'Mari El',
      'Moscow', 'Moscow Province', 'Murmansk',
      'Nizhny Novgorod', 'Novgorod', 'Novosibirsk',
      'Omsk', 'Orenburg', 'Oryol',
      'Penza', 'Perm Krai', 'Primorsky Krai',
      'Pskov', 'Adygea', 'Bashkortostan',
      'Buryat', 'Dagestan', 'Ingushetia',
      'Kalmykia', 'Karelia', 'Khakassia',
      'Mordovia', 'North Ossetia-Alania', 'Tatarstan',
      'Rostov', 'Ryazan', 'Saint Petersburg',
      'Sakha', 'Sakhalin', 'Samara',
      'Saratov', 'Smolensk', 'Stavropol Krai',
      'Sverdlovsk', 'Tambov', 'Tomsk',
      'Tula', 'Tver', 'Tyumen',
      'Tuva', 'Udmurt', 'Ulyanovsk',
      'Vladimir', 'Volgograd', 'Vologda',
      'Voronezh', 'Yamalo-Nenets Okrug', 'Yaroslavl',
      'Zabaykalsky Krai'
    ]
  },
  { id: 172, country: 'Rwanda', zones: [] },
  { id: 173, country: 'Samoa', zones: [] },
  { id: 174, country: 'San Marino', zones: [] },
  { id: 175, country: 'São Tomé & Príncipe', zones: [] },
  { id: 176, country: 'Saudi Arabia', zones: [] },
  { id: 177, country: 'Senegal', zones: [] },
  { id: 178, country: 'Serbia', zones: [] },
  { id: 179, country: 'Seychelles', zones: [] },
  { id: 180, country: 'Sierra Leone', zones: [] },
  { id: 181, country: 'Singapore', zones: [] },
  { id: 182, country: 'Sint Maarten', zones: [] },
  { id: 183, country: 'Slovakia', zones: [] },
  { id: 184, country: 'Slovenia', zones: [] },
  { id: 185, country: 'Solomon Islands', zones: [] },
  { id: 186, country: 'Somalia', zones: [] },
  {
    id: 187,
    country: 'South Africa',
    zones: [
      'Eastern Cape',
      'Free State',
      'Gauteng',
      'KwaZulu-Natal',
      'Limpopo',
      'Mpumalanga',
      'North West',
      'Northern Cape',
      'Western Cape'
    ]
  },
  {
    id: 188,
    country: 'South Georgia & South Sandwich Islands',
    zones: []
  },
  {
    id: 189,
    country: 'South Korea',
    zones: [
      'Busan', 'North Chungcheong',
      'South Chungcheong', 'Daegu',
      'Daejeon', 'Gangwon',
      'Gwangju City', 'North Gyeongsang',
      'Gyeonggi', 'South Gyeongsang',
      'Incheon', 'Jeju',
      'North Jeolla', 'South Jeolla',
      'Sejong', 'Seoul',
      'Ulsan'
    ]
  },
  { id: 190, country: 'South Sudan', zones: [] },
  {
    id: 191,
    country: 'Spain',
    zones: [
      'A Coruña',
      'Álava',
      'Albacete',
      'Alicante',
      'Almería',
      'Asturias Province',
      'Ávila',
      'Badajoz',
      'Balears Province',
      'Barcelona',
      'Burgos',
      'Cáceres',
      'Cádiz',
      'Cantabria Province',
      'Castellón',
      'Ceuta',
      'Ciudad Real',
      'Córdoba',
      'Cuenca',
      'Girona',
      'Granada',
      'Guadalajara',
      'Gipuzkoa',
      'Huelva',
      'Huesca',
      'Jaén',
      'La Rioja Province',
      'Las Palmas',
      'León',
      'Lleida',
      'Lugo',
      'Madrid Province',
      'Málaga',
      'Melilla',
      'Murcia',
      'Navarra',
      'Ourense',
      'Palencia',
      'Pontevedra',
      'Salamanca',
      'Santa Cruz de Tenerife',
      'Segovia',
      'Seville',
      'Soria',
      'Tarragona',
      'Teruel',
      'Toledo',
      'Valencia',
      'Valladolid',
      'Biscay',
      'Zamora',
      'Zaragoza'
    ]
  },
  { id: 192, country: 'Sri Lanka', zones: [] },
  { id: 193, country: 'St. Barthélemy', zones: [] },
  { id: 194, country: 'St. Helena', zones: [] },
  { id: 195, country: 'St. Kitts & Nevis', zones: [] },
  { id: 196, country: 'St. Lucia', zones: [] },
  { id: 197, country: 'St. Martin', zones: [] },
  { id: 198, country: 'St. Pierre & Miquelon', zones: [] },
  { id: 199, country: 'St. Vincent & Grenadines', zones: [] },
  { id: 200, country: 'Sudan', zones: [] },
  { id: 201, country: 'Suriname', zones: [] },
  { id: 202, country: 'Svalbard & Jan Mayen', zones: [] },
  { id: 203, country: 'Sweden', zones: [] },
  { id: 204, country: 'Switzerland', zones: [] },
  { id: 205, country: 'Taiwan', zones: [] },
  { id: 206, country: 'Tajikistan', zones: [] },
  { id: 207, country: 'Tanzania', zones: [] },
  {
    id: 208,
    country: 'Thailand',
    zones: [
      'Amnat Charoen',
      'Ang Thong',
      'Bangkok',
      'Bueng Kan',
      'Buri Ram',
      'Chachoengsao',
      'Chai Nat',
      'Chaiyaphum',
      'Chanthaburi',
      'Chiang Mai',
      'Chiang Rai',
      'Chon Buri',
      'Chumphon',
      'Kalasin',
      'Kamphaeng Phet',
      'Kanchanaburi',
      'Khon Kaen',
      'Krabi',
      'Lampang',
      'Lamphun',
      'Loei',
      'Lopburi',
      'Mae Hong Son',
      'Maha Sarakham',
      'Mukdahan',
      'Nakhon Nayok',
      'Nakhon Pathom',
      'Nakhon Phanom',
      'Nakhon Ratchasima',
      'Nakhon Sawan',
      'Nakhon Si Thammarat',
      'Nan',
      'Narathiwat',
      'Nong Bua Lam Phu',
      'Nong Khai',
      'Nonthaburi',
      'Pathum Thani',
      'Pattani',
      'Pattaya',
      'Phang Nga',
      'Phatthalung',
      'Phayao',
      'Phetchabun',
      'Phetchaburi',
      'Phichit',
      'Phitsanulok',
      'Phra Nakhon Si Ayutthaya',
      'Phrae',
      'Phuket',
      'Prachin Buri',
      'Prachuap Khiri Khan',
      'Ranong',
      'Ratchaburi',
      'Rayong',
      'Roi Et',
      'Sa Kaeo',
      'Sakon Nakhon',
      'Samut Prakan',
      'Samut Sakhon',
      'Samut Songkhram',
      'Saraburi',
      'Satun',
      'Sing Buri',
      'Si Sa Ket',
      'Songkhla',
      'Sukhothai',
      'Suphanburi',
      'Surat Thani',
      'Surin',
      'Tak',
      'Trang',
      'Trat',
      'Ubon Ratchathani',
      'Udon Thani',
      'Uthai Thani',
      'Uttaradit',
      'Yala',
      'Yasothon'
    ]
  },
  { id: 209, country: 'Timor-Leste', zones: [] },
  { id: 210, country: 'Togo', zones: [] },
  { id: 211, country: 'Tokelau', zones: [] },
  { id: 212, country: 'Tonga', zones: [] },
  { id: 213, country: 'Trinidad & Tobago', zones: [] },
  { id: 214, country: 'Tristan da Cunha', zones: [] },
  { id: 215, country: 'Tunisia', zones: [] },
  { id: 216, country: 'Turkey', zones: [] },
  { id: 217, country: 'Turkmenistan', zones: [] },
  { id: 218, country: 'Turks & Caicos Islands', zones: [] },
  { id: 219, country: 'Tuvalu', zones: [] },
  { id: 220, country: 'U.S. Outlying Islands', zones: [] },
  { id: 221, country: 'Uganda', zones: [] },
  { id: 222, country: 'Ukraine', zones: [] },
  {
    id: 223,
    country: 'United Arab Emirates',
    zones: [
      'Abu Dhabi',
      'Ajman',
      'Dubai',
      'Fujairah',
      'Ras al-Khaimah',
      'Sharjah',
      'Umm al-Quwain'
    ]
  },
  {
    id: 224,
    country: 'United Kingdom',
    zones: [
      'British Forces',
      'England',
      'Northern Ireland',
      'Scotland',
      'Wales'
    ]
  },
  {
    id: 225,
    country: 'United States',
    zones: [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'Washington DC',
      'Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'U.S. Virgin Islands',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
      'Armed Forces Americas',
      'Armed Forces Europe',
      'Armed Forces Pacific'
    ]
  },
  {
    id: 226,
    country: 'Uruguay',
    zones: [
      'Artigas', 'Canelones',
      'Cerro Largo', 'Colonia',
      'Durazno', 'Flores',
      'Florida', 'Lavalleja',
      'Maldonado', 'Montevideo',
      'Paysandú', 'Río Negro',
      'Rivera', 'Rocha',
      'Salto', 'San José',
      'Soriano', 'Tacuarembó',
      'Treinta y Tres'
    ]
  },
  { id: 227, country: 'Uzbekistan', zones: [] },
  { id: 228, country: 'Vanuatu', zones: [] },
  { id: 229, country: 'Vatican City', zones: [] },
  {
    id: 230,
    country: 'Venezuela',
    zones: [
      'Amazonas', 'Anzoátegui',
      'Apure', 'Aragua',
      'Barinas', 'Bolívar',
      'Carabobo', 'Cojedes',
      'Delta Amacuro', 'Federal Dependencies',
      'Capital', 'Falcón',
      'Guárico', 'Vargas',
      'Lara', 'Mérida',
      'Miranda', 'Monagas',
      'Nueva Esparta', 'Portuguesa',
      'Sucre', 'Táchira',
      'Trujillo', 'Yaracuy',
      'Zulia'
    ]
  },
  { id: 231, country: 'Vietnam', zones: [] },
  { id: 232, country: 'Wallis & Futuna', zones: [] },
  { id: 233, country: 'Western Sahara', zones: [] },
  { id: 234, country: 'Yemen', zones: [] },
  { id: 235, country: 'Zambia', zones: [] },
  { id: 236, country: 'Zimbabwe', zones: [] }
]
export default options
