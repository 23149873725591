import request from "./index";

/* 登录管理 */
export const loginApi = (params) => request.post('/login', params);

/* 分类管理 */
export const getCategorylists = (params) => request.get('/getcategorylists', params);
export const getSecondCategorylists = (params) => request.get('/getsecondcategorylists', params);
export const addCategory = (params) => request.post('/addcategory', params);
export const storeCategory = (params) => request.post('/storecategory', params);
export const delCategory = (params) => request.post('/delcategory', params);

/* 商品管理 */
export const getGoodslist = (params) => request.get('/getgoodslist', params);
export const addGoods = (params) => request.post('/addgoods', params);
export const getGoodsCatelist = (params) => request.get('/getgoodscatelist', params);
export const delGood = (params) => request.post('/delgood', params);
export const storeGood = (params) => request.post('/storegood', params);
export const putonGoods = (params) => request.post('/putongoods', params);
export const getGoodInfo = (params) => request.get('/editgood', params);

/* 订单管理 */
export const getOrderLists = (params) => request.get('/getorderlists', params);
export const getOrderDetail = (params) => request.get('/getorderdetail', params);
export const storeBuyerDetail = (params) => request.post('/storebuyerdetail', params);
export const storeAddressDetail = (params) => request.post('/storeaddressdetail', params);
export const changeOrderStatus = (params) => request.post('/changeorderstatus', params);

/* 发货管理 */
export const getShippingList = (params) => request.get('/shippinglist', params);
export const addShipping = (params) => request.post('/addshipping', params);
export const getExpress = (params) => request.get('/getexpress', params);
export const addShippingDetail = (params) => request.get('/addshippingdetail', params);
export const delExprss = (params) => request.post('/delexprss', params);

/* 权限管理 */
export const getAdminList = (params) => request.get('/adminlists', params);
export const registerAdmin = (params) => request.post('/register', params);
export const editAdmin = (params) => request.post('/editadmins', params);
export const delAdmin = (params) => request.post('/deladmin', params);

/* 客户管理 */
export const getUserlist = (params) => request.get('/userlists', params);
export const getUserInfo = (params) => request.get('/edituser', params);
export const storeUser = (params) => request.post('/storeuser', params);

/* 运费管理 */
export const getShippinglist = (params) => request.get('/shippinglists', params);
export const addShippingSetup = (params) => request.post('/adddelivery', params);
export const getShippingInfo = (params) => request.get('/editshipping', params);
export const editShipping = (params) => request.post('/storeshipping', params);
export const delShipping = (params) => request.post('/delshipping', params);
export const getAreaList = () => request.get('/getarealists');

/* 盲盒管理 */
export const addBlindBoxCategory = (params) => request.post('/addbrand', params);
export const getBlindBoxCategory = (params) => request.get('/brandlists', params);
export const getBlindBoxCategoryDetail = (params) => request.get('/editbrand', params);
export const changeBlindBoxCategory = (params) => request.post('/storebrand', params);
export const deleteBlindBoxCategory = (params) => request.post('/delbrand', params);
export const addBlindBoxProduct = (params) => request.post('/addproduct', params);
export const getBlindBoxProducts = (params) => request.get('/productlists', params);
export const getBlindBoxProductProductData = (params) => request.get('/editproduct', params);
export const changeBlindBoxProduct = (params) => request.post('/storeproduct', params);
export const deleteBlindBoxProduct = (params) => request.post('/delproduct', params);
export const getRechargeList = (params) => request.get('/rechargeLists', params);
export const getRechargeDetail = (params) => request.get('/rechargeDetail', params);
export const getBlindBoxOrderList = (params) => request.get('/boxOrderlists', params);
export const getBlindBoxOrderDetail = (params) => request.get('/getOrderDetail', params);