<template>
  <div>
    <el-card style="margin-bottom: 20px">
      <el-form
        class="query-form"
        :inline="true"
        :model="formQuery"
        ref="formRef"
      >
        <el-form-item label="用户名" prop="user_name"
          ><el-input v-model="formQuery.user_name"
        /></el-form-item>
        <el-form-item label="充值订单号" prop="recharge_order_id"
          ><el-input v-model="formQuery.recharge_order_id"
        /></el-form-item>
        <el-form-item label="充值状态" prop="recharge_status">
          <el-select v-model="formQuery.recharge_status" placeholder="Select">
            <el-option :value="1" label="充值成功" />
            <el-option :value="2" label="充值失败" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button :icon="Brush" @click="resetForm" />
          <el-button type="primary" :icon="Search" @click="getData"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table :height="tableHeight" :data="rechargeList" style="width: 100%">
        <el-table-column type="selection" width="55" />
        <el-table-column prop="id" label="ID" sortable />
        <el-table-column prop="username" label="充值人" sortable />
        <el-table-column
          prop="recharge_order_id"
          label="充值订单号"
          width="250"
          sortable
        />
        <el-table-column prop="recharge_status" label="充值状态">
          <template #default="scope">
            <el-tag v-if="scope.row.recharge_status === 1" type="success"
              >充值成功</el-tag
            >
            <el-tag v-if="scope.row.recharge_status === 2" type="danger"
              >充值失败</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column prop="recharge_amount" label="充值金额" />
        <el-table-column
          prop="created_at"
          label="创建时间"
          min-width="200"
          sortable
        >
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-icon>
                <timer />
              </el-icon>
              <span style="margin-left: 10px">{{ scope.row.created_at }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="140">
          <template #default="scope">
            <el-button type="primary" @click="openDetailDialog(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
        <template #empty>
          <div style="margin: 50px 0">
            <el-empty
              v-if="rechargeList.length === 0"
              description="暂时还没有数据哦"
            />
          </div>
        </template>
      </el-table>
      <div style="padding-top: 15px">
        <el-pagination
          layout="total, prev, pager, next"
          :current-page="pageData.pageNum"
          :page-size="pageData.pageSize"
          :total="pageData.total"
          @current-change="handleCurrentChange"
          @prev-click="handleCurrentChangePrev"
          @next-click="handleCurrentChangeNext"
          background
        />
      </div>
    </el-card>
    <el-dialog
      title="充值详情"
      v-model="DetailDialogVisible"
      center
      width="60%"
    >
      <el-descriptions :column="1" title="充值信息" border>
        <el-descriptions-item label="充值订单号">{{
          rechargeDetail.recharge_order_id
        }}</el-descriptions-item>
        <el-descriptions-item label="充值人">{{
          rechargeDetail.username
        }}</el-descriptions-item>
        <el-descriptions-item label="充值状态">
          <el-tag v-if="rechargeDetail.recharge_status === 1" type="success"
            >充值成功</el-tag
          >
          <el-tag v-if="rechargeDetail.recharge_status === 2" type="danger"
            >充值失败</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item label="支付错误信息">{{
          rechargeDetail.error_msg
        }}</el-descriptions-item>
        <el-descriptions-item label="充值金额">{{
          rechargeDetail.recharge_amount
        }}</el-descriptions-item>
        <el-descriptions-item label="充值时间">{{
          rechargeDetail.created_at
        }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions
        :column="1"
        title="充值用户信息"
        style="margin-top: 20px"
        border
      >
        <el-descriptions-item label="充值人姓">{{
          rechargeDetail.card_info.first_name
        }}</el-descriptions-item>
        <el-descriptions-item label="充值人名">{{
          rechargeDetail.card_info.last_name
        }}</el-descriptions-item>
        <el-descriptions-item label="充值卡号">{{
          rechargeDetail.card_info.card_number
        }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>
<script>
import { getRechargeDetail, getRechargeList } from "@/axios/api";
import {
  Brush,
  Delete,
  Edit,
  Search,
  Timer,
  WarningFilled,
} from "@element-plus/icons-vue";
import { onMounted, ref } from "vue";
export default {
  name: "blindBoxesRechargeList",
  components: { Timer },
  setup() {
    const pageData = ref({
      total: 0,
      pageNum: 1,
      pageSize: 50,
    });
    const formQuery = ref({
      user_name: "",
      recharge_order_id: "",
      recharge_status: "",
    });
    const rechargeList = ref([]);

    getData();
    async function getData() {
      try {
        let params = formQuery.value;
        params.page = pageData.value.pageNum;
        params.limit = pageData.value.pageSize;
        const data = await getRechargeList({ params });
        rechargeList.value = data.data;
        pageData.value.total = data.data.length;
      } catch (error) {
        console.log(error);
      }
    }

    // 重置表单
    const formRef = ref();
    const resetForm = () => {
      formRef.value.resetFields();
      getData();
    };
    // 分页，处理函数
    const handleCurrentChangePrev = (val) => {
      pageData.value.pageNum = val;
    };
    const handleCurrentChange = (val) => {
      pageData.value.pageNum = val;
      getData();
    };
    const handleCurrentChangeNext = (val) => {
      pageData.value.pageNum = val;
    };

    const tableHeight = ref("0px");
    onMounted(() => {
      const extraHeight = document.querySelector(".query-form").offsetHeight;
      tableHeight.value = `calc(100vh - ` + (extraHeight + 270) + `px)`;
    });

    const DetailDialogVisible = ref(false);
    const rechargeDetail = ref({});
    const openDetailDialog = async (item) => {
      try {
        let params = { recharge_id: item.id };
        const data = await getRechargeDetail({ params });
        rechargeDetail.value = data.data;
        DetailDialogVisible.value = true;
      } catch (error) {
        console.log(error);
      }
    };
    return {
      Brush,
      Search,
      Edit,
      Delete,
      WarningFilled,

      formRef,
      pageData,
      formQuery,
      rechargeList,
      tableHeight,
      rechargeDetail,
      DetailDialogVisible,

      getData,
      resetForm,
      handleCurrentChangePrev,
      handleCurrentChange,
      handleCurrentChangeNext,
      openDetailDialog,
    };
  },
};
</script>
<style lang="less" scoped></style>
